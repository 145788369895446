// BASIC COLORS
export const primaryColor = '#09A68F';
export const primaryLight = '#0FDBBD';
export const secondaryLight = '#4AF5DC';
// export const tertiaryColor = '#81A2E0';
// SCREEN COLORS
export const datingColor = '#927fec';
//export const guidesColor = '#89a3e4';
export const guidesColor = '#BE4597';
export const guidesSecondaryColor = '#db76c4';
export const groupsColor = '#38c1ad';
export const recoveryColor = '#81D9E0';
export const soberHelpColor = '#69D8ED';
export const soberHelpColorLight = '#98EFFF';
export const travelColor = '#E6D757';
export const friendColor = '#09A68F';
export const hotlineColor = '#69D8ED';
export const hotlineIconColor = '#EFF4F8';
export const searchBarColor = '#B1B1B1';
export const newPageBackground = '#F7F7F7';
export const pageBackground = '#F5F5F5';
export const borderColor = '#DBDBDB';
export const recoveryIconColor = '#64C8F3';
export const trendingPostTitleColor = '#0aa68f';
export const trendingPostGreenBg = '#0aa68f';
export const trendingPostBlueBg = '#43b4d8';
//BackgroundColors
export const inputBackground = '#FDFDFD';
export const datingBannerBackground = 'rgba(187, 172, 255,.4)';
export const hotlineBannerBackground = 'rgba(105, 216, 237,.4)';
export const opaqueWhiteBackground = 'rgba(255,255,255,0.1)';
export const notificationBadgeBackground = '#EB5292';
export const backgroundColorZicorn = '#e4ebeb';
export const hotlineCardBackgroundBlue = '#2AAEE7';
export const subTabsBackGround = '#EDEDED';
export const audioBackground = '#1B7083';
export const audioHomeBackground = '#d4e3e8';
// Text Colors
export const textColor = '#8b8b8b';
export const textColorWhite = '#ffffff';
export const textColorDark = '#0D0D0D';
export const textColorLight = '#FCFCFC';
export const textColorBlack = '#000000';
export const textColorGrey = '#8B8B8B';
export const textColorDarkGrey = '#3c3c3c';
export const textColorDarkerGrey = '#5B5B5B';
export const textColorShuttleGrey = '#5E6164';
export const textColorGrey70 = '#b3b3b3';
export const darkCyanBlue = '#262A2F';
export const textBannerDark = '#2E2E2E';
export const textColorGreen = '#006200';
// Onboarding Colors
export const onboardingDark = '#2b2b2b';
export const onboardingLight = '#454545';
// Alpha Coloring
export const alpha10Percent = '1a';
export const alpha40Percent = '66';
// Button Colors
export const genericButtonBackground = '#e9e9e9';
export const disabledButtonBackground = '#EAEAEA';
export const unselectedButtoncolor = '#9F9F9F';
// Modal Colors
export const modalPatchColor = '#E8E8E8';
export const ModalBackground = '#F8F8F8';
// Card Colors
export const cardBackground = '#FFFFFF';
// red - cancel, etc.
export const red = '#e18784';
// blue action button color
export const blue = '#0000ff';
export const fbBlue = '#3b5998';
// guide image container color
export const pinkColor = '#DB76C4';
export const pendingFriendRequestColor = '#0DA78F';
export const newTextColor = '#575757';
export const newHeadingColor = '#2E2E2E';
export const newHeadingColorWhite = '#FFFFFF';
export const newTextColorDescription = '#818181';
//CheckBox
export const checkBoxOutline = '#D9DFE5';
//BorderColors
export const seperatorColor = '#EFEFEF';
export const textSeparatorColor = '#DCDCDC';
export const white = '#FFFFFF';
//BannerColors
// : Dating
export const DatingBannerColors = ['#E79AC8', '#B477D9', '#927FEC', '#6E59D0'];
export const GroupsBannerColors = [
  '#33FCCC',
  '#43D1BC',
  '#31C2AD',
  '#13BAA1',
  '#069C86'
];
export const SoberHelpBannerColors = [
  '#98EFFF',
  '#7FC2F3',
  '#69D8ED',
  '#50C5DB',
  '#3CB3CA'
];

//

export const onlineIndicator = '#1cd253';

// export const lightPink = '#E79AC8';
// export const darkPink = '#B477D9';
// export const lightPurple = '#927FEC';
// export const darkPurple = '#6E59D0';
// // :Groups
// export const cyan_Shade1 = '#33FCCC';
// export const cyan_Shade2 = '#43D1BC';
// export const cyan_Shade3 = '#31C2AD', ;
// export const cyan_Shade4 = '#13BAA1', ;
// export const cyan_Shade5 = '#069C86';
// :SoberHelp

// SoberHelp Colors Login
export const greenIcon = '#09A68F';
export const placeHolderColor = '#68807D';
export const loginButton = '#09A68F';
export const blackText = '#2E2E2E';
export const whiteScreen = '#F5F5F5';
export const greenScreen = '#31C6B0';
export const bigIcon = '#1E3E3A';
export const subtitle = '#F5F5F5';
export const blueIcon = '#42b4d8';
export const activeHeaderBg = '#ededed';
export const dashStatsCount = '#05221f';
export const secondaryTitle = '#1e3e3a';
export const textTags = '#1e3e3a';
export const bgTags = '#d9f0f7';
export const sadFaceBg = '#db4545';
export const textPersonOnDash = '#011412';
export const switchInactiveColor = '#8d9998';
export const grayButtonBg = '#ececec';
export const subPointsModal = '#3b5956';
export const pink = '#eb5292';
export const grayBg = '#f7f7f7';
export const cardNoteBg = '#99e0ef';
export const cardNoteCornerBg = '#7ab3bf';
export const cardNoteAddBg = '#f3f3f3';
export const cardNoteAddCornerBg = '#c2c2c2';
export const cardNoteAddIcon = '#adadad';
export const notDownloadedAppText = '#3b5956';
export const defaultAvatarPatientsBg = '#f1f1f1';
export const notPairedYetBtnBg = '#d96da7';
export const selectArrowIcon = '#aeaeae';
export const calendarBg = '#09a68f';
export const uploadBtnBg = '#31c6b0';
export const previewImageBg = '#f2f2f2';
export const textTimeLastMessage = '#8d9998';
export const infoColor = '#BB7751';
export const checkChatColor = '#81E299';
