import axios from 'axios';
import axiosRetry from 'axios-retry';
const BASE_URL =
  process.env.REACT_APP_CENTERS_API_URL ||
  'https://centers01-dev-p6p6i.ondigitalocean.app';
const connection_abort_timeout = 13000;
const request_timeout = 10000;
const GET = 'get';
const POST = 'post';
const PUT = 'put';
const DELETE = 'delete';

class ApiHandler {
  callAxios = async (
    baseUrl = BASE_URL,
    endpoint,
    method,
    headers = {},
    data = null
  ) => {
    axiosRetry(axios, { retries: 2, shouldResetTimeout: true });
    let response = null;
    let source = axios.CancelToken.source();
    this.abortTimeOut = setTimeout(() => {
      // if the response is null after timeout abort the request
      if (response === null) {
        source.cancel && source.cancel();
      }
    }, connection_abort_timeout);

    response = await axios({
      method: method,
      url: baseUrl + endpoint,
      timeout: request_timeout,
      data: data,
      cancelToken: source.token,
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
      headers: headers
    })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return {
            name: 'Error',
            message: 'Request_cancelled'
          };
        }
        console.log(error.toJSON());
        return error.toJSON();
      })
      .finally(() => {
        if (this.abortTimeOut) clearTimeout(this.abortTimeOut);
      });
    if (response != null) {
      return response;
    } else {
      return {};
    }
  };

  testEndPoint = async (headers, queryParams) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/treatment_centers',
      GET,
      headers
    );
    return response;
  };

  sendInvitation = async (headers, body) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/memberships',
      POST,
      headers,
      body
    );
    return response;
  };

  updateMembership = async (headers, body, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${id}`,
      PUT,
      headers,
      body
    );
    return response;
  };

  updateStatusMembership = async (headers, body, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${id}/update_status`,
      PUT,
      headers,
      body
    );
    return response;
  };

  sendEmailMembership = async (headers, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${id}/send_email`,
      POST,
      headers
    );
    return response;
  };

  uploadInvitationsFile = async (headers, body) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/memberships/invite_patients',
      POST,
      headers,
      body
    );
    return response;
  };

  reserveMemberships = async (headers, body) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/treatment_centers/1/reserved_memberships',
      POST,
      headers,
      body
    );
    return response;
  };

  getPatients = async (headers, queryParans) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships?${serialize(queryParans)}`,
      GET,
      headers
    );
    return response;
  };

  getTasks = async (headers, queryParans) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/tcenter_daily_tasks',
      GET,
      headers
    );
    return response;
  };

  createTask = async (headers, body) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/tcenter_daily_tasks',
      POST,
      headers,
      body
    );
    return response;
  };

  updateTask = async (headers, body, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/tcenter_daily_tasks/${id}`,
      PUT,
      headers,
      body
    );
    return response;
  };

  deleteTask = async (headers, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/tcenter_daily_tasks/${id}`,
      DELETE,
      headers
    );
    return response;
  };

  getDashboardStats = async (headers) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/home/stats`,
      GET,
      headers
    );
    return response;
  };

  getMembershipsDashboard = async (headers, queryParams) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships?${serialize(queryParams)}`,
      GET,
      headers
    );
    return response;
  };

  addNote = async (headers, body, membership_id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${membership_id}/tcenter_notes`,
      POST,
      headers,
      body
    );
    return response;
  };

  updateNote = async (headers, body, membership_id, note_id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${membership_id}/tcenter_notes/${note_id}`,
      PUT,
      headers,
      body
    );
    return response;
  };

  deleteNote = async (headers, membership_id, note_id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${membership_id}/tcenter_notes/${note_id}`,
      DELETE,
      headers
    );
    return response;
  };

  getNotesMembership = async (headers, membership_id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${membership_id}/tcenter_notes`,
      GET,
      headers
    );
    return response;
  };

  getAddictions = async (headers) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/addictions`,
      GET,
      headers
    );
    return response;
  };

  getCalendarInfo = async (headers, membership_id, params) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${membership_id}/get_calendar_report?${serialize(
        params
      )}`,
      GET,
      headers
    );
    return response;
  };

  getCurrentMembershipInfo = async (headers, membership_id, params) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${membership_id}`,
      GET,
      headers
    );
    return response;
  };

  getTreatmentCenterInfo = async (headers, treatmentcenter_id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/treatment_centers/${treatmentcenter_id}`,
      GET,
      headers
    );
    return response;
  };

  updateTreatmentCenterInformation = async (
    headers,
    body,
    treatmentcenter_id
  ) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/treatment_centers/${treatmentcenter_id}`,
      PUT,
      headers,
      body
    );
    return response;
  };

  updateStaffMemberInformation = async (
    headers,
    body,
    treatmentcenter_id,
    member_id
  ) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/treatment_centers/${treatmentcenter_id}/center_staff_members/${member_id}`,
      PUT,
      headers,
      body
    );
    return response;
  };

  getPresignedUrlTreatmentCenter = async (headers) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/treatment_centers/get_presigned_url',
      GET,
      headers
    );
    return response;
  };

  getAlumniEvents = async (headers, queryParans) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/alumni_events',
      GET,
      headers
    );
    return response;
  };

  getPresignedUrlEvents = async (headers, queryParans) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/alumni_events/get_presigned_url',
      GET,
      headers
    );
    return response;
  };

  createAlumniEvent = async (headers, body) => {
    const response = await this.callAxios(
      BASE_URL,
      '/v3/alumni_events',
      POST,
      headers,
      body
    );
    return response;
  };

  updateAlumniEvent = async (headers, body, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/alumni_events/${id}`,
      PUT,
      headers,
      body
    );
    return response;
  };

  deleteAlumniEvent = async (headers, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/alumni_events/${id}`,
      DELETE,
      headers
    );
    return response;
  };

  recordAssistanceHelp = async (headers, body, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${id}/add_assistance_record`,
      POST,
      headers,
      body
    );
    return response;
  };

  createSingleReservation = async (headers, body, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/reserved_memberships`,
      POST,
      headers,
      body
    );
    return response;
  };

  getReservations = async (headers, queryParams) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/reserved_memberships?${serialize(queryParams)}`,
      GET,
      headers
    );
    return response;
  };

  deleteReservation = async (headers, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/reserved_memberships/${id}`,
      DELETE,
      headers
    );
    return response;
  };

  updateReservation = async (headers, body, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/reserved_memberships/${id}`,
      PUT,
      headers,
      body
    );
    return response;
  };

  deleteMembership = async (headers, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${id}/destroy_membership`,
      DELETE,
      headers
    );
    return response;
  };

  deleteMembershipFromImportProcess = async (headers, id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/reserved_memberships/${id}/delete_from_import`,
      DELETE,
      headers
    );
    return response;
  };

  getDailyReport = async (id, headers, queryParams) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${id}/my_daily_report?${serialize(queryParams)}`,
      GET,
      headers
    );
    return response;
  };

  getPeriodicalReport = async (id, headers, queryParams) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/memberships/${id}/my_periodical_report?${serialize(queryParams)}`,
      GET,
      headers
    );
    return response;
  };

  getExperts = async (headers, tc_id) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/treatment_centers/${tc_id}/tcenter_experts`,
      GET,
      headers
    );
    return response;
  };

  addStaffMember = async (headers, tc_id, data) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/treatment_centers/${tc_id}/add_staff_member`,
      POST,
      headers,
      data
    );
    return response;
  };

  finishInitialSetup = async (headers, tc_id, data) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/treatment_centers/${tc_id}/finish_init_config`,
      PUT,
      headers,
      data
    );
    return response;
  };

  getNotificationsList = async (headers, queryParams = {}) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/alumni_notifications?${serialize(queryParams)}`,
      GET,
      headers
    );
    return response;
  };

  sendAlumniNotification = async (headers, tc_id, data) => {
    const response = await this.callAxios(
      BASE_URL,
      `/v3/treatment_centers/${tc_id}/send_alumni_notification`,
      POST,
      headers,
      data
    );
    return response;
  };
}

const serialize = (obj) => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
    }
  return str.join('&');
};

export default ApiHandler;
