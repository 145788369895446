import React, { Suspense } from 'react';
import { Route, Redirect, useLocation, Switch } from 'react-router-dom';
import { Routes } from './Routes';
import { Load } from '../components/';

import { AuthRoutes, OpenRoutes } from './AppRoutes';

function AuthLinks() {
  const { pathname } = useLocation();
  let redirectTo = Routes.login;

  const _AuthLinks = [...OpenRoutes, ...AuthRoutes];

  for (let i = 0; i < _AuthLinks.length; i++) {
    const link = _AuthLinks[i];

    if (link.path === pathname) {
      redirectTo = pathname;
      break;
    }
  }

  return (
    <Suspense fallback={<Load />}>
      <Switch>
        {_AuthLinks.map((item, index) => {
          return (
            <Route
              exact
              key={index}
              path={item.path}
              component={item.component}
            />
          );
        })}
        <Redirect to={redirectTo} />
      </Switch>
    </Suspense>
  );
}

export { AuthLinks };
