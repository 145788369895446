export const getUserFields = (currentUser) => {
  let user = { id: currentUser.id };
  if (currentUser.firstName || currentUser.lastName) {
    user.name = `${currentUser?.firstName || ''}${
      currentUser?.lastName ? ' ' + currentUser.lastName : ''
    }`;
  }
  return user;
};

export const canCreateAChannel = (staffMembers, currentGetStreamUser) => {
  if (currentGetStreamUser?.user?.role !== 'moderator') return true;
  if (staffMembers.length === 0) return false;
  let result = staffMembers.filter(
    (item) => item.auth0_id === currentGetStreamUser?.user?.id
  );
  if (result.length > 0) return true;
  return false;
};
