import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import Notfound from '../pages/Notfound';
import Demo from '../demo/Demo';

// import Dating from '../pages/Dating';
// import Post from '../pages/Post';
// import Badge from '../pages/Badge';
// import Group from '../pages/Group';
// import Storie from '../pages/Storie';
// import Member from '../pages/Member';
// import Email from '../pages/Email';
// import Emailopen from '../pages/Emailopen';

// import Account from '../pages/Account';
// import Contactinfo from '../pages/Contactinfo';
// import Socialaccount from '../pages/Socialaccount';
// import Password from '../pages/Password';
// import Payment from '../pages/Payment';
// import Notification from '../pages/Notification';
// import Helpbox from '../pages/Helpbox';

// import Register from '../pages/Register';
// import Forgot from '../pages/Forgot';

// import ShopOne from '../pages/ShopOne';
// import ShopTwo from '../pages/ShopTwo';
// import ShopThree from '../pages/ShopThree';
// import Singleproduct from '../pages/Singleproduct';
// import Cart from '../pages/Cart';
// import Checkout from '../pages/Checkout';

// import Live from '../pages/Live';
// import Job from '../pages/Job';
// import Event from '../pages/Event';
// import Hotel from '../pages/Hotel';
// import Videos from '../pages/Videos';
// import Comingsoon from '../pages/Comingsoon';

// import Grouppage from '../pages/Grouppage';
// import Userpage from '../pages/Userpage';
// import Authorpage from '../pages/Authorpage';
// import Hotelsingle from '../pages/Hotelsingle';
// import Analytics from '../pages/Analytics';

// import Audios from '../pages/Audio';

// import ChangePassword from '../pages/ChangePassword';

import { Routes as RoutePaths } from './Routes';

const Login = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Login/'
  )
);

const Dashboard = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Dashboard'
  )
);

const AlumniEvent = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/AlumniEvents'
  )
);

const Patients = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Patients'
  )
);

const Tasks = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Tasks'
  )
);

const Profile = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Profile'
  )
);

const Chat = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Chat/'
  )
);

const Settings = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Settings'
  )
);

const ContactNow = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/ContactNow'
  )
);

const Reservations = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Reservations'
  )
);

const UserSettings = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/UserSettings'
  )
);

const StaffMembers = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/StaffMembers'
  )
);

const Wizard = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Wizard'
  )
);

const Notifications = lazy(() =>
  import(
    /* webpackChunkName: "login" */ /* webpackPreload: true */ '../pages/Notifications'
  )
);

const NotFound = () => {
  console.log('not found');
  return <Redirect to={RoutePaths.dashboard} />;
};

export const NestingRouting = [
  {
    path: `${RoutePaths.messages}`,
    nesting: [
      {
        path: '/',
        component: <Chat />,
        exact: true
      },
      {
        path: '/:id',
        component: <Chat />,
        exact: false
      }
    ]
  }
];

export const ProtectedRoutes = [
  {
    path: RoutePaths.dashboard,
    component: Dashboard
  },
  {
    path: `${RoutePaths.defaultsettings}`,
    component: Settings
  },
  {
    path: `${RoutePaths.patients}`,
    component: Patients
  },
  {
    path: `${RoutePaths.alumnievent}`,
    component: AlumniEvent
  },
  {
    path: `${RoutePaths.tasks}`,
    component: Tasks
  },
  {
    path: `${RoutePaths.profile}`,
    component: Profile
  },
  {
    path: RoutePaths.contactnow,
    component: ContactNow
  },
  {
    path: RoutePaths.reservations,
    component: Reservations
  },
  {
    path: RoutePaths.usersettings,
    component: UserSettings
  },
  {
    path: RoutePaths.members,
    component: StaffMembers
  },
  {
    path: RoutePaths.notifications,
    component: Notifications
  }
];

// export const ProtectedRoutes = [

//     {
//         path: RoutePaths.home,
//         component: Home
//     },
//     {
//         path: RoutePaths.hotlines,
//         component: Hotlines
//     },
//     {
//         path: RoutePaths.soberhelp,
//         component: SoberHelp
//     },
//     {
//         path: RoutePaths.guides,
//         component: BoozelessGuide
//     },
//     {
//         path: RoutePaths.dating,
//         component: Dating
//     },
//     {
//         path: `${RoutePaths.defaultbadge}`,
//         component: Badge
//     },
//     {

//         path: `${RoutePaths.defaultgroup}`,
//         component: Group
//     },
//     {

//         path: `${RoutePaths.defaultstorie}`,
//         component: Storie
//     },
//     {

//         path: `${RoutePaths.defaultemailbox}`,
//         component: Email
//     },
//     {

//         path: `${RoutePaths.defaultemailopen}`,
//         component: Emailopen
//     },
//     {

//         path: `${RoutePaths.defaultsettings}`,
//         component: Settings
//     },
//     {

//         path: `${RoutePaths.defaultvideo}`,
//         component: Videos
//     },
//     {

//         path: `${RoutePaths.audio}`,
//         component: Audios
//     },
//     {

//         path: `${RoutePaths.defaultanalytics}`,
//         component: Analytics
//     },

//     {

//         path: `${RoutePaths.accountinformation}`,
//         component: Account
//     },
//     {

//         path: `${RoutePaths.defaultmember}`,
//         component: Member
//     },
//     {

//         path: `${RoutePaths.contactinformation}`,
//         component: Contactinfo
//     },
//     {

//         path: `${RoutePaths.socialaccount}`,
//         component: Socialaccount
//     },
//     {

//         path: `${RoutePaths.password}`,
//         component: Password
//     },
//     {

//         path: `${RoutePaths.payment}`,
//         component: Payment
//     },
//     {

//         path: `${RoutePaths.defaultnotification}`,
//         component: Notification
//     },

//     {

//         path: `${RoutePaths.helpbox}`,
//         component: Helpbox
//     },

//     {

//         path: `${RoutePaths.shop1}`,
//         component: ShopOne
//     },
//     {

//         path: `${RoutePaths.shop2}`,
//         component: ShopTwo
//     },
//     {

//         path: `${RoutePaths.shop3}`,
//         component: ShopThree
//     },
//     {

//         path: `${RoutePaths.singleproduct}`,
//         component: Singleproduct
//     },
//     {

//         path: `${RoutePaths.cart}`,
//         component: Cart
//     },
//     {

//         path: `${RoutePaths.checkout}`,
//         component: Checkout
//     },
//     {

//         path: `${RoutePaths.defaultmessage}`,
//         component: Chat
//     },
//     {

//         path: `${RoutePaths.defaultlive}`,
//         component: Live
//     },

//     {

//         path: `${RoutePaths.defaultjob}`,
//         component: Job
//     },
//     {

//         path: `${RoutePaths.defaultevent}`,
//         component: Event
//     },
//     {

//         path: `${RoutePaths.defaulthotel}`,
//         component: Hotel
//     },
//     {

//         path: `${RoutePaths.grouppage}`,
//         component: Grouppage
//     },
//     {

//         path: `${RoutePaths.userpage}`,
//         component: Userpage
//     },
//     {

//         path: `${RoutePaths.authorpage}`,
//         component: Authorpage
//     },
//     {

//         path: `${RoutePaths.comingsoon}`,
//         component: Comingsoon
//     },
//     {
//         path: `${RoutePaths.defaulthoteldetails}`,
//         component: Hotelsingle
//     }
// ]

export const OpenRoutes = [
  {
    path: RoutePaths.notfound,
    component: Notfound
  }
  // {
  //   path: RoutePaths.changepassword,
  //   component: ChangePassword
  // }
  // {
  //     path: RoutePaths.redirect,
  //     component: NotFound
  // }
];
export const AuthRoutes = [
  {
    path: RoutePaths.login,
    component: Login
  }
  // {
  //   path: RoutePaths.register,
  //   component: Register
  // },
  // {
  //   path: RoutePaths.forgot,
  //   component: Forgot
  // }
];

export const WizardRoutes = [
  {
    path: RoutePaths.wizard,
    component: Wizard
  }
];
