import { MyStorage } from '../../helpers/MyStorage';
import {
  USER_TOKEN,
  USER_DATA,
  SET_STAFF_MEMBERS,
  SET_EXPERTS,
  SET_IS_OWNER
} from '../Types';

export const setUserToken = (token) => {
  new MyStorage().setUserToken(token);
  return {
    type: USER_TOKEN,
    payload: token
  };
};

export const setUserData = (currentUser) => {
  return {
    type: USER_DATA,
    payload: currentUser
  };
};

export const setStaffMembers = (staffMembers) => {
  return {
    type: SET_STAFF_MEMBERS,
    payload: staffMembers
  };
};

export const setExpertsMembers = (experts) => {
  return {
    type: SET_EXPERTS,
    payload: experts
  };
};

export const setIsOwnerValue = (value) => {
  return {
    type: SET_IS_OWNER,
    payload: value
  };
};
