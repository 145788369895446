
import { gql, useMutation } from '@apollo/client';

export const PEER_USER_DATA = gql`
  fragment peerUserData on User {
    id
    firstName
    lastName
    bio
    dtBio
    occupation
    avatarUrl
    dateOfBirth
    isDatingCurrentUser
    rc_username
    interests
    profileAssets {
      id
      originalUrl
    }
    selectedLocation {
      label
    }
    beacon {
      active
    }
  }
`;

export const BEACON_DATA = gql`
  fragment beaconData on Beacon {
    active
    lastActivatedAt
    nextPossibleAt
    remainingTimeSeconds
    activeUntil
  }
`;

export const FRIENDSHIP_DATA = gql`
  fragment friendshipData on Friendship {
    id
    insertedAt
    otherUserId
    friend {
      ...peerUserData
    }
    requester {
      ...peerUserData
    }
    requestMessage
    kind
    confirmedAt
    cancelledAt
  }
  ${PEER_USER_DATA}
`;

export const FRIENDSHIP_DATA_REQUEST = gql`
  fragment profileFriendshipData on Friendship {
    id
    kind
    cancelledAt
    confirmedAt
    directThreadId
    requester_rc_username
    friend_rc_username
    ender {
      id
    }
    friend {
      id
      active
    }
    requester {
      id
      active
    }
  }
`;

export const EVENT_DATA = gql`
  fragment eventData on Event {
    id
    organizer
    startsAt
    endsAt
    title
    venue
    insertedAt
    kind
    cost
    locationLabel
    category
    description
    externalUrl
    externalImageUrl
    creator {
      id
      avatarUrl
      firstName
      lastName
    }
    attendances {
      event {
        id
      }
      user {
        ...peerUserData
      }
    }
  }
  ${PEER_USER_DATA}
`;

export const THREAD_DATA_WITH_OTHER_PARTICIPATIONS = gql`
  fragment threadDataWithOtherParticipations on Thread {
    assets {
      id
      originalUrl
    }
    category
    creator {
      id
    }
    description
    displayInformation {
      iconUrl
      name
      directOtherUser {
        id
        isDatingCurrentUser
        avatarUrl
      }
    }
    id
    kind
    lastMessage {
      insertedAt
      id
      body
      user {
        id
        firstName
        lastName
        avatarUrl
      }
    }
    name
    ownParticipation {
      id
      lastSeenAt
    }
    status
    suggestedEvent {
      ...eventData
    }
  }
  ${EVENT_DATA}
`;

export const USER_PROFILE = gql`
  fragment userProfile on User {
    attendances {
      user {
        id
      }
      event {
        id
        startsAt
        description
        title
        category
        insertedAt
      }
    }
    avatarUrl
    selectedLocation {
      label
      location {
        latitude
        longitude
      }
    }
    firstName
    id
    dtAgeTo
    isPrivate
    email
    ownHotline {
      id
      displayName
    }
    threads {
      ...threadDataWithOtherParticipations
    }
    pnVibration
    pushNotifications
    pnHotline
    facebookId
    dateOfBirth
    soberSince
    dtAgeFrom
    isDating
    dtGender
    showRecovery
    showDating
    occupation
    hotlineEnabled

    beacon {
      ...beaconData
    }
    lastName
    dtDistanceM
    invitations {
      sentAt
    }
    incomingFriendshipRequests {
      ...friendshipData
    }
    friendships {
      ...friendshipData
    }
    allFriendships {
      ...friendshipData
    }
    events {
      id
    }
    active
    gender
    showSobriety
    pnSound
    bio
    interests
    dtBio
    phoneNumber
    createdAssets {
      id
    }
    profileAssets {
      id
      originalUrl
    }
  }
  ${FRIENDSHIP_DATA}
  ${BEACON_DATA}
  ${THREAD_DATA_WITH_OTHER_PARTICIPATIONS}  
`;

export const PROFILE_FRIENDSHIP_DATA = gql`
  fragment profileFriendshipData on Friendship {
    id
    kind
    cancelledAt
    confirmedAt
    directThreadId
    requester_rc_username
    friend_rc_username
    ender {
      id
    }
    friend {
      id
      active
    }
    requester {
      id
      active
    }
  }
`;
