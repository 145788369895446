import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import Icon from '../common/Icon';
import { Colors } from '../../styles';
import ApiHandler from '../../api/api';
import { FlashMessage } from '../';
import { getHeaders } from '../../helpers/Util';
import {
  customStylesSmallScreen,
  customStylesBigScreen
} from '../../styles/modalsStyles';

import * as Yup from 'yup';
import './modal.scss';
import {
  addPatient,
  addReservedMembership
} from '../../redux/actions/AppActions';

const initialState = {
  email: '',
  full_name: '',
  phone_number: '',
  type: 'invite',
  reservation_type: 'single',
  fb_email: '',
  switch_value: 'individual',
  status: ''
};

const newInvitationSchema = Yup.object({
  email: Yup.string()
    .email()
    .when('switch_value', (switch_value) => {
      if (switch_value === 'individual')
        return Yup.string().required('Email is required.');
    }),
  full_name: Yup.string().when('switch_value', (switch_value) => {
    if (switch_value === 'individual')
      return Yup.string().required('Full Name is required.');
  }),
  reservation_type: Yup.string(),
  phone_number: Yup.string()
    .transform((value) => (value ? value.replace(/[^\d]/g, '') : ''))
    .min(11, 'Phone Number must contain at least 11 characters')
    .when('reservation_type', {
      is: (reservation_type) => {
        return reservation_type !== 'single';
      },
      then: Yup.string().required('Phone Number is required.')
    }),
  fb_email: Yup.string().email(),
  switch_value: Yup.string()
});

const ModalInvitePatient = ({
  isOpenFlag,
  closeModal,
  token,
  item,
  updateMembershipCB,
  inviteByFileCB
}) => {
  const [isLoading, setLoading] = useState(false);
  const [selectedFile, setFile] = useState(null);
  const inputFile = React.useRef(null);
  const [activeButton, setActiveButton] = useState('individual');
  const dispatch = useDispatch();

  useEffect(() => {
    if (!item) formik.resetForm();
    setFile(null);
    setActiveButton('individual');
  }, [isOpenFlag]);

  const formik = useFormik({
    validationSchema: newInvitationSchema,
    initialValues: item
      ? {
          email: item.email,
          full_name: item.full_name,
          phone_number: item.phone_number,
          status: item.status
        }
      : initialState,
    onSubmit: (values) => {
      let tPhone = '';
      tPhone = values.phone_number
        .replace(/ /g, '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '');

      if (item) {
        updateMembershipCB({ ...values, phone_number: tPhone });
      } else {
        setLoading(true);
        if (
          activeButton === 'multiple' &&
          formik.values.reservation_type === 'single'
        ) {
          makeReservationInfo({
            phone_number: tPhone,
            fb_email: values.fb_email
          });
        } else {
          sendInvitation({ ...values, phone_number: tPhone ? tPhone : '+1' });
        }
      }
    }
  });

  useEffect(() => {
    if (item) {
      formik.setValues({
        email: item.email,
        full_name: item.full_name,
        phone_number: item.phone_number,
        status: item.status
      });
    }
  }, [item]);

  const sendInvitation = (body) => {
    new ApiHandler()
      .sendInvitation(getHeaders(token), JSON.stringify(body))
      .then((response) => {
        if (response && response.status && response.status === 200) {
          dispatch(addPatient(response.data.membership));
          closeModal();
          FlashMessage.succesS('The invitation was sent.');
          sendEmailInvitation(response.data.membership.id);
          formik.resetForm();
        } else {
          FlashMessage.error(response.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        FlashMessage.error('There was an error, try again later.');
        setLoading(false);
      });
  };

  const makeReservationInfo = (body) => {
    new ApiHandler()
      .createSingleReservation(getHeaders(token), JSON.stringify(body), 3)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          dispatch(addReservedMembership(response.data.reserved_membership));
          closeModal();
          FlashMessage.succesS('The reservation was created.');
          formik.resetForm();
        } else {
          FlashMessage.error(response.data.message);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        FlashMessage.error('There was an error, try again later.');
        setLoading(false);
      });
  };

  const sendEmailInvitation = (id) => {
    new ApiHandler()
      .sendEmailMembership(getHeaders(token), id)
      .then((response) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  const uploadFile = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('file', selectedFile);
      const headers = {
        ...getHeaders(token),
        'Content-Type': 'multipart/form-data'
      };
      inviteByFileCB(headers, formData);
      closeModal();
    }
  };

  const selectFile = (e) => {
    const files = e.target.files;
    if (files.length) {
      let file = e.target.files[0];
      setFile(file);
    }
  };

  return (
    <Modal
      isOpen={isOpenFlag}
      onRequestClose={closeModal}
      style={
        window.innerWidth <= 1024
          ? customStylesSmallScreen
          : customStylesBigScreen
      }
      ariaHideApp={false}
    >
      <div className="bg-white modal-switch-component">
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex align-items-center close-button-container">
            <i
              className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
              onClick={() => closeModal()}
            >
              <Icon icon="close" size={25} color={Colors.blackText} />
            </i>
            <h4
              className="fw-500 font-xs m-0 pointer d-none d-md-block"
              onClick={() => closeModal()}
            >
              Close
            </h4>
          </div>
          {item ? (
            <div className="card-body p-0 d-flex justify-content-center">
              <h4 className="fw-600 font-md m-0 mt-1 pointer">
                Invite Patient
              </h4>
            </div>
          ) : (
            <div className="ms-auto d-flex">
              <div className="d-flex flex-row bg-greylight p-1 rounded-xxxl">
                <div
                  className={`d-flex w-100 py-2 px-5 lh-28 text-center font-xss fw-600 rounded-xxl pointer align-items-center st-switch-btn ${
                    activeButton === 'individual' ? 'activated' : ''
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveButton('individual');
                    formik.setFieldValue('switch_value', 'individual');
                  }}
                >
                  <h4 className="fw-500 font-xs m-0 pointer d-none d-sm-block">
                    Invite (Email)
                  </h4>
                </div>
                <div
                  className={`d-flex w-100 py-2 px-5 lh-28 text-center fw-600 rounded-xxl pointer align-items-center st-switch-btn ${
                    activeButton === 'multiple' ? 'activated' : ''
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveButton('multiple');
                    formik.setFieldValue('switch_value', 'multiple');
                  }}
                >
                  <h4 className="fw-500 font-xs m-0 pointer d-none d-sm-block">
                    Reservation (Phone)
                  </h4>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex justify-content-center">
            {item || activeButton === 'individual' ? (
              <h4 className="fw-400 font-xs m-0 mt-4 text-center sub-point">
                Hello, You can invite your Patients <br /> to track their
                sobriety
              </h4>
            ) : (
              <h4 className="fw-400 font-xs m-0 mt-2 text-center sub-point">
                Hello, you can reserve memberships for your Patients
              </h4>
            )}
          </div>
        </div>
        <div className="row d-flex align-content-center">
          <div
            className={`card shadow-none border-0 ms-auto me-auto login-card pt-1 `}
          >
            <div className={`card-body rounded-0 px-0 pt-1`}>
              <form
                className="pt-2 sober-track-form"
                onSubmit={formik.handleSubmit}
              >
                {item || activeButton === 'individual' ? (
                  <>
                    <div className="form-group icon-input mb-3">
                      <i className="d-flex">
                        <Icon icon="email" size={23} color={Colors.greenIcon} />
                      </i>
                      <input
                        id="email"
                        name="email"
                        type="email"
                        className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                        placeholder="Email"
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      {formik.errors.email && formik.touched.email && (
                        <p className="p-0 mb-0 me-2 text-danger text-center">
                          {formik.errors.email}.
                        </p>
                      )}
                    </div>
                    <div className="form-group icon-input mb-3">
                      <div className="form-group icon-input mb-3">
                        <i className="d-flex">
                          <Icon
                            icon="sponsor"
                            size={23}
                            color={Colors.greenIcon}
                          />
                        </i>
                        <input
                          id="full_name"
                          name="full_name"
                          type="text"
                          className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                          placeholder="Full Name"
                          onChange={formik.handleChange}
                          value={formik.values.full_name}
                        />
                        {formik.errors.full_name &&
                          formik.touched.full_name && (
                            <p className="p-0 mb-0 me-2 text-danger text-center">
                              {formik.errors.full_name}.
                            </p>
                          )}
                      </div>
                    </div>
                    {/* <div className="form-group icon-input mb-3">
                      <div className="form-group icon-input mb-3">
                        <i className="d-flex">
                          <Icon
                            icon="phone"
                            size={23}
                            color={Colors.greenIcon}
                          />
                        </i>
                        <InputMask
                          name="phone_number"
                          className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                          mask="+1 (999) 999-9999"
                          maskChar=""
                          onChange={formik.handleChange}
                          value={formik.values.phone_number}
                          placeholder="Phone Number"
                        />
                        {formik.errors.phone_number &&
                          formik.touched.phone_number && (
                            <p className="p-0 mb-0 me-2 text-danger text-center">
                              {formik.errors.phone_number}.
                            </p>
                          )}
                      </div>
                    </div> */}
                  </>
                ) : (
                  <div className="card-body p-0 uploader-container d-flex align-items-center">
                    <div className="checkout-payment card border-0 mb-3">
                      <div className=" mb-4">
                        <div className="d-flex justify-content-between mb-2">
                          <div>
                            <input
                              type="radio"
                              name="reservation_type"
                              value="single"
                              checked={
                                formik.values.reservation_type === 'single'
                              }
                              onChange={() =>
                                formik.setFieldValue(
                                  'reservation_type',
                                  'single'
                                )
                              }
                            />
                            <label className="fw-600 font-xsss text-grey-900 ms-2">
                              Single
                            </label>
                          </div>
                          <div>
                            <input
                              type="radio"
                              name="reservation_type"
                              value="multiple"
                              checked={
                                formik.values.reservation_type === 'multiple'
                              }
                              onChange={() =>
                                formik.setFieldValue(
                                  'reservation_type',
                                  'multiple'
                                )
                              }
                            />
                            <label className="payment-label fw-600 font-xsss text-grey-900 ms-2">
                              Multiple, by CSV file
                            </label>
                          </div>
                        </div>
                        <div className="payment-info">
                          <p className="font-xssss lh-24 text-grey-500 mb-1">
                            {formik.values.reservation_type === 'single'
                              ? 'You can reserve a membership for a Patient we just need their phone number.'
                              : 'You can upload a CSV file with two columns: phone_number and fb_email (can be empty), so you can pre-register users, to use Loosid SAM.'}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {activeButton === 'multiple' && (
                  <>
                    {formik.values.reservation_type === 'multiple' ? (
                      <div>
                        <div className="card-body p-0 uploader-container d-flex align-items-center justify-content-center">
                          <figure
                            className="avatar me-2 mb-0 pointer"
                            onClick={(e) => {
                              inputFile.current.click();
                            }}
                          >
                            <i
                              className={`btn-round-xxxl me-0 shadow d-flex justify-content-center align-items-center`}
                            >
                              <Icon
                                icon="upload"
                                size={25}
                                color={Colors.blackText}
                              />
                            </i>
                          </figure>
                          <h4
                            className="loosid-black-text text-center font-xss fw-500 mt-2 ms-2 text-uploader pointer"
                            onClick={(e) => {
                              inputFile.current.click();
                            }}
                          >
                            {'Select the CSV file.'}
                          </h4>
                        </div>
                        {selectedFile ? (
                          <div>
                            <h2 className="font-xsss text-center mt-2">
                              {selectedFile.name}
                              <i
                                className={`d-inline-block justify-content-center align-items-center pointer ms-2`}
                                onClick={() => setFile(null)}
                              >
                                <Icon
                                  icon="trash"
                                  size={18}
                                  color={Colors.textColorBlack}
                                  className=""
                                />
                              </i>
                            </h2>
                          </div>
                        ) : null}
                      </div>
                    ) : (
                      <div>
                        <div className="form-group icon-input mb-3">
                          <div className="form-group icon-input mb-3">
                            <i className="d-flex">
                              <Icon
                                icon="phone"
                                size={23}
                                color={Colors.greenIcon}
                              />
                            </i>
                            <InputMask
                              name="phone_number"
                              className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                              mask="+1 (999) 999-9999"
                              maskChar=""
                              onChange={formik.handleChange}
                              value={formik.values.phone_number}
                              placeholder="Phone Number"
                            />
                            {formik.errors.phone_number &&
                              formik.touched.phone_number && (
                                <p className="p-0 mb-0 me-2 text-danger text-center">
                                  {formik.errors.phone_number}.
                                </p>
                              )}
                          </div>
                        </div>
                        {/* <div className="form-group icon-input mb-3">
                          <i className="d-flex">
                            <Icon
                              icon="email"
                              size={23}
                              color={Colors.greenIcon}
                            />
                          </i>
                          <input
                            id="fb_email"
                            name="fb_email"
                            type="email"
                            className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                            placeholder="FB Email"
                            onChange={formik.handleChange}
                            value={formik.values.fb_email}
                          />
                        </div> */}
                      </div>
                    )}
                  </>
                )}

                <input
                  type="file"
                  id="file"
                  ref={inputFile}
                  onChange={selectFile}
                  style={{ display: 'none' }}
                  accept=".csv"
                />

                <div className="form-group mb-1 mt-5">
                  {item || activeButton === 'individual' ? (
                    <button
                      className="form-control text-center text-white fw-600 border-0 p-0 sober-track-btn d-flex align-items-center justify-content-center"
                      type="submit"
                    >
                      <p className="p-0 mb-0 me-2 font-xs fw-500">
                        {item ? 'Update' : 'Send Invite'}
                      </p>
                      {isLoading ? (
                        <div
                          className="spinner-border loading-indicator me-1"
                          role="status"
                        ></div>
                      ) : (
                        <i
                          className={`d-flex justify-content-center align-items-center ms-2`}
                        >
                          <Icon icon="plus" size={25} color={Colors.white} />
                        </i>
                      )}
                    </button>
                  ) : (
                    <button
                      className="form-control text-center text-white fw-600 border-0 p-0 sober-track-btn d-flex align-items-center justify-content-center"
                      type="button"
                      onClick={() => {
                        formik.values.reservation_type === 'single'
                          ? formik.submitForm()
                          : uploadFile();
                      }}
                    >
                      <p className="p-0 mb-0 me-2 font-xs fw-500">
                        {formik.values.reservation_type === 'single'
                          ? 'Submit'
                          : 'Upload File'}
                      </p>
                      {isLoading ? (
                        <div
                          className="spinner-border loading-indicator me-1"
                          role="status"
                        ></div>
                      ) : (
                        <>
                          {formik.values.reservation_type === 'multiple' && (
                            <i
                              className={`d-flex justify-content-center align-items-center ms-2`}
                            >
                              <Icon
                                icon="upload"
                                size={25}
                                color={Colors.white}
                              />
                            </i>
                          )}
                        </>
                      )}
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalInvitePatient;
