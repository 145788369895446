import { gql } from '@apollo/client';

import { FRIENDSHIP_DATA, FRIENDSHIP_DATA_REQUEST } from './fragments';

export const CONFIRM_FRIENDSHIP = gql`
  mutation ConfirmFriendship($friendshipId: ID!) {
    confirmFriendship(friendshipId: $friendshipId) {
      ...friendshipData
    }
  }
  ${FRIENDSHIP_DATA}
`;

export const CANCEL_FRIENDSHIP = gql`
  mutation CancelFriendship($friendshipId: ID!) {
    cancelFriendship(friendshipId: $friendshipId) {
      ...friendshipData
    }
  }
  ${FRIENDSHIP_DATA}
`;


export const REQUEST_FRIENDSHIP = gql`
  mutation RequestFriendship($request: NewFriendship!) {
    requestFriendship(request: $request) {
      ...profileFriendshipData
    }
  }
  ${FRIENDSHIP_DATA_REQUEST}
`;

export const CONFIRM_FRIENDSHIP_PROFILE = gql`
  mutation ConfirmFriendship($friendshipId: ID!) {
    confirmFriendship(friendshipId: $friendshipId) {
        id
        kind
        confirmedAt
        cancelledAt
    }
  }
`;