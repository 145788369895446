import React, { Suspense } from 'react';
import {
  Route,
  useHistory,
  Switch,
  matchPath,
  Redirect,
  useLocation
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setNavigationPath,
  setActiveHeaderButton
} from '../redux/actions/AppActions';
import { useAuth0 } from '@auth0/auth0-react';

import {
  OpenRoutes,
  ProtectedRoutes,
  ProtectedRoutesWithoutExact,
  NestingRouting,
  WizardRoutes
} from './AppRoutes';
import { Load } from '../components';

import {
  Header,
  Leftnav,
  Popupchat,
  Appfooter,
  MiniChat,
  MessagesBar
} from '../components';
import { Routes as RoutePaths } from '../routing/Routes';
import { fetchAddictions } from '../redux/reducers/AppReducer';
import { getHeaders } from '../helpers/Util';

const user_authorization_text =
  'https://centers.loosidapp.com/user_authorization';

function ProtectedLinks() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getAccessTokenSilently, user } = useAuth0();

  const {
    user: { currentUser }
  } = useSelector((state) => state);

  const is_wizard = user[user_authorization_text].app_metadata.wizard || false;

  React.useEffect(() => {
    return history.listen((location) => {
      if (location?.pathname) {
        dispatch(setNavigationPath(location?.pathname));
        // setDefaultValueActiveHeaderButton(location)
      }
    });
  }, [history]);

  React.useEffect(() => {
    // setDefaultValueActiveHeaderButton(history?.location)
    dispatch(setNavigationPath(history.location?.pathname));
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const currentToken = await getAccessTokenSilently();
    dispatch(fetchAddictions(getHeaders(currentToken)));
  };

  // const setDefaultValueActiveHeaderButton = (location) => {
  //     const queryParams = new URLSearchParams(location.search);
  //     if (location?.pathname === RoutePaths.home) {
  //         const options = ['feed', 'hotlines'];
  //         // console.log(query)
  //         // console.log(options.includes(query.get("tab")))
  //         if (queryParams.get("tab") !== null && options.includes(queryParams.get("tab"))) {
  //             dispatch(setActiveHeaderButton(queryParams.get("tab") ))
  //         } else {
  //             dispatch(setActiveHeaderButton('feed'))
  //         }
  //     } else {
  //         if (location?.pathname === RoutePaths.soberhelp) {
  //             dispatch(setActiveHeaderButton('sober-help'))
  //         } else if (location?.pathname === RoutePaths.dating) {
  //             dispatch(setActiveHeaderButton('d-new'))
  //         } else if (location?.pathname === RoutePaths.guides) {
  //             const guideNames = ['Online Events', 'Events', 'Travel'];
  //             const opts = ['guide-oe', 'guide-e', 'guide-t'];
  //             if (queryParams.get("tab") !== null && opts.includes(queryParams.get("tab"))) {
  //                 const indexArray = opts.indexOf(queryParams.get("tab"))
  //                 dispatch(setActiveHeaderButton(guideNames[indexArray] ))
  //             } else {
  //                 dispatch(setActiveHeaderButton('Restaurants'))
  //             }
  //         } else if (
  //             matchPath(location?.pathname, {
  //                 path: '/user/:username',
  //                 exact: true,
  //                 strict: false
  //               })
  //         ) {
  //             dispatch(setActiveHeaderButton('p-posts'))
  //         }
  //         else{
  //             dispatch(setActiveHeaderButton(''))
  //         }
  //     }
  // }

  const getDefaultRedirectTo = () => {
    if (is_wizard) {
      return RoutePaths.wizard;
    }

    if (currentUser.is_expert === true || currentUser.is_support === true) {
      return RoutePaths.messages;
    }

    return RoutePaths.dashboard;
  };

  const allowAllRoutes = (user) => {
    if (user.is_expert === true || user.is_support === true) {
      return false;
    }
    return true;
  };

  const { pathname } = useLocation();
  let redirectTo = getDefaultRedirectTo();
  let _NestingRoutes = [];

  NestingRouting.map((r) => {
    const pathFather = r.path;
    r.nesting.map((nest) => {
      if (nest.path === '/') {
        _NestingRoutes.push({ path: pathFather, exact: true });
      } else {
        _NestingRoutes.push({
          path: `${pathFather}${nest.path}`,
          exact: nest.exact
        });
      }
    });
  });

  if (Object.keys(currentUser).length === 0) {
    return <Load />;
  }

  const _ProtectedLinks = [...OpenRoutes, ...ProtectedRoutes];

  if (is_wizard) {
    const _WizardLinks = [...WizardRoutes, ...OpenRoutes];
    for (let i = 0; i < _WizardLinks.length; i++) {
      const link = _WizardLinks[i];

      if (link.path === pathname) {
        redirectTo = pathname;
        break;
      }
    }

    return (
      <>
        <Header />
        <Suspense fallback={<Load />}>
          <Switch>
            {_WizardLinks.map((item, index) => {
              return (
                <Route
                  exact
                  key={index}
                  path={item.path}
                  component={item.component}
                />
              );
            })}
            <Redirect to={redirectTo} />
          </Switch>
        </Suspense>
      </>
    );
  }

  for (let i = 0; i < _ProtectedLinks.length; i++) {
    const link = _ProtectedLinks[i];
    if (link.path === pathname) {
      redirectTo = pathname;
      break;
    }
  }

  for (let j = 0; j < _NestingRoutes.length; j++) {
    const route = _NestingRoutes[j];
    const match = matchPath(pathname, route);
    if (match) {
      redirectTo = pathname;
      break;
    }
  }

  return (
    <>
      <Header />
      {/* <Leftnav /> */}
      {/* <MessagesBar /> */}
      <Suspense fallback={<Load />}>
        {allowAllRoutes(currentUser) &&
          _ProtectedLinks.map((item, index) => {
            return (
              <Route
                exact
                key={index}
                path={item.path}
                component={item.component}
              />
            );
          })}

        {NestingRouting.map((item, index) => {
          return (
            <Route path={item.path} key={`main-nesting-route${index}`}>
              <Switch>
                {item.nesting.map((route, i) => {
                  return (
                    <Route
                      exact={route.exact}
                      path={`${item.path}${route.path}`}
                      children={route.component}
                      key={`nested-route${index}`}
                    />
                  );
                })}
              </Switch>
            </Route>
          );
        })}
      </Suspense>

      {/* <Popupchat /> */}
      <MiniChat />
      <Appfooter />
      <Redirect to={redirectTo} />
    </>
  );
}

export { ProtectedLinks };
