import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useAuth0 } from '@auth0/auth0-react';
import ApiHandler from '../../api/api';
import Icon from '../common/Icon';
import { Colors } from '../../styles';
import './modal.scss';
import 'draft-js/dist/Draft.css';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { useDispatch } from 'react-redux';
import { FlashMessage } from '../';
import { getHeaders } from '../../helpers/Util';
import loadable from '@loadable/component';
import {
  customStylesSmallScreen,
  customLeftStylesBigScreen as customStylesBigScreen
} from '../../styles/modalsStyles';
import { GetStreamChatID } from '../../config/constants';
import { canCreateAChannel } from '../../utils/getStreamHelpers';
import { setMiniChat } from '../../redux/actions/ChatActions';

const NotesSection = loadable(() => import('./common/NoteSection'));
const HeaderUser = loadable(() => import('./common/HeaderUser'));
const BodyProfile = loadable(() => import('./common/UserBody'));
const ReportBody = loadable(() => import('./common/ReportBody'));

const ModalUser = ({
  isOpenFlag,
  closeModal,
  item,
  getAddictionNameCB,
  editPatientCB,
  updateStatusCB,
  loadingCBFlag,
  openHistoryModalCB,
  staffMembers,
  getStreamClient
}) => {
  const [activeButton, setActiveButton] = useState('profile');
  const [patient, patientsNotes] = useState([]);
  const [loadingNotes, setLoadingNotes] = useState(true);
  const [newConversationEnable, setNewConversationEnable] = useState(false);
  const [busy, setBusy] = useState(false);
  const [channel, setChannel] = useState(null);
  const [membershipSoberToolProgress, setMembershipSoberToolProgress] =
    useState(null);
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();
  const getToken = async () => {
    const currentToken = await getAccessTokenSilently();
    return currentToken;
  };

  const lookChannel = async () => {
    const filter = {
      type: GetStreamChatID,
      members: [item.external_id, getStreamClient?.user?.id]
    };
    const sort = [{ last_message_at: -1 }];
    const channel = await getStreamClient.queryChannels(filter, sort, {
      watch: true, // this is the default
      state: true
    });

    if (channel.length > 0) {
      setNewConversationEnable(true);
      setChannel(channel[0]);
    } else {
      if (getStreamClient?.user?.role === 'moderator') {
        if (canCreateAChannel(staffMembers, getStreamClient)) {
          setNewConversationEnable(true);
        }
      } else {
        // regular user (role === 'user')
        setNewConversationEnable(true);
      }
    }
  };

  const openChat = () => {
    if (!newConversationEnable) return;
    if (channel) {
      initChat(channel);
    } else {
      createChannel(item.external_id);
    }
  };

  const createChannel = async (userID) => {
    const channel = getStreamClient.channel(GetStreamChatID, {
      members: [userID, getStreamClient?.user?.id]
    });
    await channel.create();
    initChat(channel);
  };

  const initChat = (channel) => {
    let user;
    for (const property in channel.state.members) {
      if (getStreamClient?.user?.id !== property) {
        user = channel.state.members[property].user;
      }
    }
    dispatch(setMiniChat({ user, channel: channel }));
    closeModal();
  };

  const fetchCalendarInfo = async (params) => {
    setLoadingNotes(true);
    const token = await getToken();
    return new ApiHandler().getCalendarInfo(getHeaders(token), item.id, params);
  };

  const getCurrentMembership = async () => {
    const token = await getToken();
    new ApiHandler()
      .getCurrentMembershipInfo(getHeaders(token), item.id)
      .then((response) => {
        if (response && response.status && response.status === 200) {
          const {
            position,
            curr_date,
            curr_week_start_pos,
            curr_week_end_pos,
            curr_week_start_date,
            curr_week_end_date,
            curr_week,
            day_in_curr_week,
            first_day
          } = response.data;

          const soberToolProgress = {
            position,
            curr_date,
            curr_week_start_pos,
            curr_week_end_pos,
            curr_week_start_date,
            curr_week_end_date,
            curr_week,
            day_in_curr_week,
            first_day
          };
          setMembershipSoberToolProgress(soberToolProgress);
        } else {
          FlashMessage.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        FlashMessage.error('There was an error, try again later.');
      })
      .finally(() => setLoadingNotes(false));
  };

  const fetchNotes = async () => {
    setLoadingNotes(true);
    const token = await getToken();
    new ApiHandler()
      .getNotesMembership(getHeaders(token), item.id)
      .then((response) => {
        // console.log(response);
        if (response && response.status && response.status === 200) {
          patientsNotes(response.data);
        } else {
          FlashMessage.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        FlashMessage.error('There was an error, try again later.');
      })
      .finally(() => setLoadingNotes(false));
  };

  const performUpdateNote = async (idNote, contentMobile, content) => {
    const token = await getToken();
    new ApiHandler()
      .updateNote(
        getHeaders(token),
        { content, content_mobile: contentMobile },
        item.id,
        idNote
      )
      .then((response) => {
        if (response && response.status && response.status === 200) {
          FlashMessage.succesS('The note was updated.');
        } else {
          FlashMessage.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        FlashMessage.error('There was an error, try again later.');
      });
  };

  const performCreateNote = async (content, contentMobile, index) => {
    const token = await getToken();
    new ApiHandler()
      .addNote(
        getHeaders(token),
        { content, content_mobile: contentMobile },
        item.id
      )
      .then((response) => {
        if (response && response.status && response.status === 200) {
          const notes = patient.map(function (note, i) {
            if (i === index) {
              return response.data.note;
            }
            return note;
          });
          patientsNotes(notes);
          FlashMessage.succesS('The note was created.');
        } else {
          FlashMessage.error(response.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        FlashMessage.error('There was an error, try again later.');
      });
  };

  const performDeleteNote = async (index, note) => {
    if (note?.id) {
      const token = await getToken();
      new ApiHandler()
        .deleteNote(getHeaders(token), item.id, note.id)
        .then((response) => {
          if (response && response.status && response.status === 204) {
            const notes = patient.filter(function (n, i) {
              if (n.id !== note.id) {
                return note;
              }
            });
            patientsNotes(notes);
            FlashMessage.succesS('The note was removed.');
          } else {
            FlashMessage.error(response.data.message);
          }
        })
        .catch((err) => {
          console.log(err);
          FlashMessage.error('There was an error, try again later.');
        });
    } else {
      removeItemFromPatientNotes(index);
    }
  };

  const removeItemFromPatientNotes = (index) => {
    const notes = patient.filter(function (note, i) {
      if (i !== index) {
        return note;
      }
    });
    patientsNotes(notes);
  };

  const requestEmailMembership = async () => {
    setBusy(true);
    const token = await getToken();
    new ApiHandler()
      .sendEmailMembership(getHeaders(token), item.id)
      .then((response) => {
        FlashMessage.succesS('The invitation was send.');
        setBusy(false);
      })
      .catch((err) => {
        setBusy(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (isOpenFlag) {
      if (item) {
        fetchNotes();
        getCurrentMembership();
        if (item.external_id) {
          lookChannel();
        }
      }
    }
    setActiveButton('profile');
    setNewConversationEnable(false);
  }, [isOpenFlag]);

  const showDeleteConfirmation = (id, note) => {
    confirmAlert({
      title: 'Delete confirmation',
      message: `Are you sure you want to delete this note?`,
      buttons: [
        {
          label: 'No',
          className: 'bg-light',
          onClick: () => {}
        },
        {
          label: 'Yes',
          onClick: () => performDeleteNote(id, note),
          className: 'bg-red'
        }
      ]
    });
  };

  return (
    <Modal
      isOpen={isOpenFlag}
      onRequestClose={closeModal}
      style={
        window.innerWidth <= 1024
          ? customStylesSmallScreen
          : customStylesBigScreen
      }
      ariaHideApp={false}
    >
      <div className="bg-white modal-switch-component">
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex align-items-center">
            <i
              className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
              onClick={() => closeModal()}
            >
              <Icon icon="close" size={25} color={Colors.blackText} />
            </i>
            <h4
              className="fw-500 font-xs m-0 pointer d-none d-md-block"
              onClick={() => closeModal()}
            >
              Close
            </h4>
          </div>
          <div className="ms-auto d-flex">
            <div className="d-flex flex-row bg-greylight p-1 rounded-xxxl">
              <div
                className={`d-flex w-100 py-2 px-5 lh-28 text-center font-xss fw-600 rounded-xxl pointer align-items-center st-switch-btn ${
                  activeButton === 'profile' ? 'activated' : ''
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveButton('profile');
                }}
              >
                <i
                  className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
                >
                  <Icon
                    icon="profile"
                    size={30}
                    color={
                      activeButton === 'profile'
                        ? Colors.greenIcon
                        : Colors.switchInactiveColor
                    }
                  />
                </i>
                <h4 className="fw-500 font-xs m-0 pointer d-none d-sm-block">
                  Profile
                </h4>
              </div>
              <div
                className={`d-flex w-100 py-2 px-5 lh-28 text-center fw-600 rounded-xxl pointer align-items-center st-switch-btn ${
                  activeButton === 'notes' ? 'activated' : ''
                }`}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveButton('notes');
                }}
              >
                <i
                  className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
                >
                  <Icon
                    icon="notes"
                    size={30}
                    color={
                      activeButton === 'notes'
                        ? Colors.greenIcon
                        : Colors.switchInactiveColor
                    }
                  />
                </i>
                <h4 className="fw-500 font-xs m-0 pointer d-none d-sm-block">
                  Notes
                </h4>
              </div>
              {item && item.status === 'active' && (
                <div
                  className={`d-flex w-100 py-2 px-5 lh-28 text-center fw-600 rounded-xxl pointer align-items-center st-switch-btn ${
                    activeButton === 'reports' ? 'activated' : ''
                  }`}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveButton('reports');
                  }}
                >
                  <i
                    className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
                  >
                    <Icon
                      icon="chartpie"
                      size={30}
                      color={
                        activeButton === 'reports'
                          ? Colors.greenIcon
                          : Colors.switchInactiveColor
                      }
                    />
                  </i>
                  <h4 className="fw-500 font-xs m-0 pointer d-none d-sm-block">
                    Reports
                  </h4>
                </div>
              )}
            </div>
          </div>
        </div>
        {item ? (
          <>
            <div className="row p-3">
              <div className="col-12 pb-2 ps-4">
                <HeaderUser
                  hasDownloadedApp={item.status === 'active'}
                  item={item}
                  editPatientCB={editPatientCB}
                  initChatCB={openChat}
                  getStreamUser={getStreamClient?.user}
                  enableNewMessage={newConversationEnable}
                  showConversationsCB={openHistoryModalCB}
                />
              </div>
            </div>
            {activeButton === 'profile' && (
              <div className="row p-3 pt-0 ps-4">
                <BodyProfile
                  hasDownloadedApp={item.status === 'active'}
                  statusMembership={item.status}
                  item={item}
                  getAddictionNameCB={getAddictionNameCB}
                  fetchCalendarInfo={fetchCalendarInfo}
                  sendInviteCB={requestEmailMembership}
                  updateStatusMembership={updateStatusCB}
                  loading={busy}
                  loadingFather={loadingCBFlag}
                />
              </div>
            )}
            {activeButton === 'notes' && (
              <NotesSection
                isLoading={loadingNotes}
                notes={patient}
                updateCB={performUpdateNote}
                createCB={performCreateNote}
                removeCB={showDeleteConfirmation}
                addNoteCB={() => {
                  console.log('click');
                  let no = [...patient];
                  no.push({});
                  patientsNotes(no);
                }}
              />
            )}
            {activeButton === 'reports' && item.status === 'active' && (
              <ReportBody
                membershipSoberToolProgress={membershipSoberToolProgress}
                item={item}
              />
            )}
          </>
        ) : null}
      </div>
    </Modal>
  );
};

export default ModalUser;
