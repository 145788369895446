import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { defaultAvatar } from '../../helpers/Util';
import { Routes } from '../../routing/Routes';
import { Colors } from '../../styles';
import Icon from '../common/Icon';
import { setUserToken } from '../../redux/actions/UserActions';
import { setClientChat } from '../../redux/actions/ChatActions';

const MainDropdownMenu = ({ isStaffMember }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [onChangeTriggered, setonChangeTriggered] = useState(false);

  const containerRef = useRef();
  const itemClicked = useRef(false);
  const { logout, user } = useAuth0();
  const dispatch = useDispatch();

  const {
    chat: { client },
    user: { currentUser, isOwner }
  } = useSelector((state) => state);

  const performLogout = () => {
    setShowDropdown(false);
    dispatch(setUserToken(''));
    if (client) {
      client.disconnectUser();
      dispatch(setClientChat(null));
    }
    logout({
      returnTo: window.location.origin
    });
  };

  return (
    <div
      className="card-body p-0 d-flex align-items-center"
      ref={containerRef}
      tabIndex={0}
      onBlur={(e) => {
        if (!onChangeTriggered) setShowDropdown(false);
      }}
    >
      <div
        className="text-center me-1 menu-icon pointer ps-0 d-none d-lg-flex"
        onClick={() => {
          if (!itemClicked.current) setShowDropdown((x) => !x);
          itemClicked.current = false;
        }}
      >
        {currentUser.image ? (
          <i
            className={`btn-round-lg d-flex justify-content-center align-items-center ms-2`}
          >
            <img
              src={currentUser.image || defaultAvatar}
              alt="user"
              className="w40 shadow-sm rounded-circle"
            />
          </i>
        ) : (
          <i
            className={`btn-round-lg d-flex justify-content-center align-items-center ms-2`}
          >
            <Icon icon="sponsor" size={25} color={Colors.greenIcon} />
          </i>
        )}
      </div>
      <div
        className={`dropdown-menu p-0 right-0 rounded-xxl border-0 shadow-lg ${
          showDropdown ? ' show' : ''
        }`}
      >
        <div className="px-4">
          <h3 className="fw-500 font-xss pb-2 ps-2 border-bottom-light pt-4">
            Hi, {user.name.substring(0, user.name.lastIndexOf('@'))}
          </h3>
          {isOwner && (
            <Link
              to={Routes.profile}
              className="pointer p-0 mb-1"
              onClick={() => setShowDropdown(false)}
            >
              <h4 className="fw-600 font-xss ps-2">Edit Center Information</h4>
            </Link>
          )}
          {isStaffMember && (
            <Link
              to={Routes.defaultsettings}
              className="pointer p-0 mb-1"
              onClick={() => setShowDropdown(false)}
            >
              <h4 className="fw-600 font-xss ps-2">Settings</h4>
            </Link>
          )}
          <h4
            className="fw-600 font-xss mb-0 ps-2 pointer pb-4"
            onClick={(e) => performLogout()}
          >
            Sign out
          </h4>
        </div>
      </div>
    </div>
  );
};

export default MainDropdownMenu;
