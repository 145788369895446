// React Required
import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { useSelector, useDispatch } from 'react-redux';
import { BrowserRouter, Switch } from 'react-router-dom';
import { StreamChat } from 'stream-chat';
import { Load } from './components';
import moment from 'moment';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Routes } from './routing/Routes';
import { ProtectedLinks } from './routing/ProtectedLinks';
import { AuthLinks } from './routing/AuthLinks';
import {
  setUserData,
  setStaffMembers,
  setExpertsMembers,
  setIsOwnerValue
} from './redux/actions/UserActions';
// import { setAddictions } from './redux/actions/AppActions';

import {
  setClientChat,
  setUnreadChatMessages,
  setChatListener,
  setLastMessageTimeStamp,
  setChannelDeleted
} from './redux/actions/ChatActions';

import ApiHandler from './api/ApiHandler';
import Api from './api/api';
import {
  GetStreamInstance_PROD,
  SUPPORT_USER_ID,
  JOSH_EXPERT_ACCOUNT
} from './config/constants';
import { getHeaders } from './helpers/Util';

// Create Import File
import './main.scss';
import './scss/_custom.scss';

const App = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const { isAuthenticated, isLoading, getAccessTokenSilently, user } =
    useAuth0();

  const initChatListener = () => {
    const chatListener = state.chat.client.on((event) => {
      if (event.total_unread_count !== null) {
        // console.log(`unread messages count is now: ${event.total_unread_count}`);
      }
      if (
        event.unread_channels !== null &&
        event.unread_channels !== undefined
      ) {
        // console.log(`unread channels count is now: ${event.unread_channels}`);
        dispatch(setUnreadChatMessages(event.unread_channels));
      }

      if (
        event.type === 'message.new' ||
        event.type === 'notification.added_to_channel'
      ) {
        dispatch(setLastMessageTimeStamp(moment().unix()));
      }

      if (['channel.deleted'].includes(event.type)) {
        dispatch(setChannelDeleted(event.cid));
      }
    });

    dispatch(setChatListener(chatListener));
  };

  const unsubscribeChatListener = () => {
    if (state.chat.listener) {
      state.chat.listener.unsubscribe();
    }
  };

  useEffect(() => {
    if (state.chat.client) {
      initChatListener();
    } else {
      unsubscribeChatListener();
    }
  }, [state.chat.client]);

  const getGetStreamName = (api_name, currentUserID) => {
    if (currentUserID === SUPPORT_USER_ID) {
      return 'Loosid Support';
    }

    if (currentUserID === JOSH_EXPERT_ACCOUNT) {
      // josh@test.com
      return 'Josh - New Leaf Detox';
    }
    return api_name;
  };

  const initStreamChat = async () => {
    const user_authorization_text =
      'https://centers.loosidapp.com/user_authorization';
    const tokenAuth0 = await getAccessTokenSilently();
    const getStreamUserID = user.sub.replace('auth0|', 'auth0_');
    const treatmentcenter_id =
      user[user_authorization_text].app_metadata.treatment_center_id;
    const is_owner = user[user_authorization_text].app_metadata.is_owner;
    dispatch(setIsOwnerValue(is_owner));
    const is_support =
      user[user_authorization_text].app_metadata.support || false;
    const is_expert =
      user[user_authorization_text].app_metadata.is_expert || false;

    if (treatmentcenter_id) {
      fetchExperts(tokenAuth0, treatmentcenter_id);
      const treatment_center = await new Api()
        .getTreatmentCenterInfo(getHeaders(tokenAuth0), treatmentcenter_id)
        .then((response) => {
          const user = {
            id: getStreamUserID,
            name: getGetStreamName(response.data.name, getStreamUserID),
            image: response.data.logo_url
          };
          // if (is_owner) {
          dispatch(setStaffMembers(response.data.staff_members));
          // }
          return user;
        })
        .catch((err) => console.log(err));

      treatment_center.is_support = is_support;
      treatment_center.is_expert = is_expert;

      if (treatment_center && treatment_center.name) {
        dispatch(setUserData(treatment_center));

        try {
          const chatClient = StreamChat.getInstance(GetStreamInstance_PROD, {
            timeout: 10000
          });
          await chatClient.connectUser(treatment_center, async () => {
            return new ApiHandler()
              .getStreamChatToken(getHeaders(tokenAuth0))
              .then((response) => {
                return response?.data?.token;
              })
              .catch((err) => console.log(err));
            // return token;
          });
          dispatch(setClientChat(chatClient));
        } catch (err) {
          console.log('error initStreamChat ', err);
        }
      }
    }
  };

  const fetchExperts = (token, treatment_center_id) => {
    return new Api()
      .getExperts(getHeaders(token), treatment_center_id)
      .then((response) => {
        if (response.status === 200) {
          dispatch(setExpertsMembers(response.data.experts));
        } else {
          console.log(response.data);
          // FlashMessage.error('There was an error loading the experts, try again later.');
        }
      });
  };

  useEffect(() => {
    if (isAuthenticated) {
      initStreamChat();
    }
  }, [isAuthenticated]);

  if (isLoading) {
    return <Load />;
  }

  return (
    <>
      <BrowserRouter basename={Routes.baseRoute}>
        <Switch>{isAuthenticated ? <ProtectedLinks /> : <AuthLinks />}</Switch>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
};

export default App;
