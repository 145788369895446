import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { Colors } from '../../styles';
import ApiHandler from '../../api/api';
import { getHeaders, hideComponent } from '../../helpers/Util';
import { FlashMessage } from '..';
import { GetStreamChatID } from '../../config/constants';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchReservedMemberships } from '../../redux/reducers/AppReducer';
import { Routes as RoutePaths } from '../../routing/Routes';
import MainDropdownMenu from './MainDropodownMenu';

import './index.scss';
import {
  ModalInvitePatient,
  ModalUser,
  ModalSearchUser,
  ModalUserChatHistory
} from '../Modals';
import Icon from '../common/Icon';
import { setMiniChat } from '../../redux/actions/ChatActions';

import { Routes } from '../../routing/Routes';

const Tiles = [
  {
    title: 'Dashboard',
    icon: 'home',
    mainClass: 'home',
    route: Routes.dashboard,
    size: 25
  },
  {
    title: 'Patients',
    icon: 'patients',
    mainClass: 'sober-help',
    route: Routes.patients,
    size: 25
  },
  {
    title: 'Messages',
    icon: 'message-empty',
    mainClass: 'audio',
    route: Routes.messages,
    size: 25
  },
  {
    title: 'Tasks',
    icon: 'task',
    mainClass: 'dating',
    route: Routes.tasks,
    size: 25
  },
  {
    title: 'Alumni Events',
    icon: 'alumni-event',
    mainClass: 'guide',
    route: Routes.alumnievent,
    size: 25
  },
  {
    title: 'Reserved M.',
    icon: 'alumni-event',
    mainClass: 'guide',
    route: Routes.reservations,
    size: 25
  }
];

function Header() {
  const [token, setToken] = useState(null);
  const [isStaffMember, setIsStaffMember] = useState(false);
  const { getAccessTokenSilently, user } = useAuth0();
  const [sideMenuItems, setSideMenu] = useState(Tiles);

  const [isOpen, setIsOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [modalIsOpen, setModalFlag] = useState(false);
  const [searchModalIsOpen, setSearchModalFlag] = useState(false);
  const [userModalIsOpen, setUserModalFlag] = useState(false);
  const [isChatHistoryModalOpen, setIsChatHistoryModalOpen] = useState(false);

  const [tmpPacient, setPacient] = useState(null);
  const getStreamUserID = user.sub.replace('auth0|', 'auth0_');

  const {
    app: { route, theme, addictions, staff_members },
    chat: { showChatBar, client },
    user: { currentUser, isOwner }
  } = useSelector((state) => state);

  const wizard =
    user['https://centers.loosidapp.com/user_authorization'].app_metadata
      .wizard || false;

  const dispatch = useDispatch();

  useEffect(() => {
    if (isOwner) {
      setSideMenu([
        ...sideMenuItems,
        ...[
          {
            title: 'Staff Members',
            icon: 'patients',
            mainClass: 'guide',
            route: Routes.members,
            size: 25
          },
          {
            title: 'Notifications',
            icon: 'message-empty',
            mainClass: 'guide',
            route: Routes.notifications,
            size: 25
          }
        ]
      ]);
    }
  }, []);

  useEffect(() => {
    getToken();
    staff_members.map((member) => {
      if (member.auth0_id === getStreamUserID) {
        setIsStaffMember(true);
      }
    });
  }, []);

  const getToken = async () => {
    const currentToken = await getAccessTokenSilently();
    setToken(currentToken);
  };

  const closeModal = () => {
    setModalFlag(false);
  };

  const closeUserModal = () => {
    setUserModalFlag(false);
  };

  const closeHistoryChatModal = () => {
    // setUserModalFlag(false);
    setIsChatHistoryModalOpen(false);
  };

  const closeSearchModal = () => {
    setSearchModalFlag(false);
  };

  const openHistoryModal = () => {
    closeModal();
    setIsChatHistoryModalOpen(true);
  };

  const openModalUser = (item) => {
    closeSearchModal(false);
    setUserModalFlag(true);
    setPacient(item);
  };

  const openInviteModal = () => {
    closeUserModal();
    setModalFlag(true);
  };

  const getAddictionName = (id) => {
    const addiction = addictions.filter((a) => a.value === id);
    return addiction.length > 0 ? addiction[0].label : '';
  };

  const createChannel = async (userID) => {
    const channel = client.channel(GetStreamChatID, {
      members: [userID, getStreamUserID]
    });
    await channel.create();
    initChat(channel);
  };

  const getChannel = async (userID) => {
    const filter = {
      type: GetStreamChatID,
      members: [userID, getStreamUserID]
    };
    const sort = [{ last_message_at: -1 }];
    const channels = await client.queryChannels(filter, sort, {
      watch: true, // this is the default
      state: true
    });

    if (channels.length > 0) {
      initChat(channels[0]);
    } else {
      createChannel(userID);
    }
  };

  const initChat = (channel) => {
    let user;
    for (const property in channel.state.members) {
      if (getStreamUserID !== property) {
        user = channel.state.members[property].user;
      }
    }
    dispatch(setMiniChat({ user, channel: channel }));
    closeUserModal();
  };

  const updatePatient = async (values) => {
    closeModal();
    const currentToken = await getAccessTokenSilently();
    new ApiHandler()
      .updateMembership(getHeaders(currentToken), values, tmpPacient.id)
      .then((response) => {
        FlashMessage.succesS('The patient information was updated.');
      })
      .catch((err) => console.log(err));
  };

  const uploadFile = (headers, body) => {
    const uploadingPromise = new Promise((resolve, reject) => {
      new ApiHandler()
        .reserveMemberships(headers, body)
        .then((response) => {
          if (response && response.status && response.status === 200) {
            const message = response.data.message;
            dispatch(fetchReservedMemberships(headers));
            resolve(message ? message : 'The file was uploaded successfully.');
          } else {
            const error = response.data.message;
            reject(error ? error : 'There was an error try again later.');
          }
        })
        .catch((err) => {
          reject('There was an error try again later.');
          console.log(err);
        });
    });

    promiseNotification(uploadingPromise);
  };

  const promiseNotification = (promise) => {
    toast.promise(promise, {
      pending: {
        render() {
          return 'loading...';
        },
        icon: false
      },
      success: {
        render({ data }) {
          return data;
        },
        theme: 'colored'
        // other options
      },
      error: {
        render({ data }) {
          console.log('error', data);
          // When the promise reject, data will contains the error
          return data;
        },
        theme: 'colored'
      }
    });
  };

  const toggleOpen = () => setIsOpen(!isOpen);
  const toggleActive = () => setIsActive(!isActive);

  const navClass = `${isOpen ? ' nav-active' : ''}`;
  const buttonClass = `${isOpen ? ' active' : ''}`;
  const searchClass = `${isActive ? ' show' : ''}`;

  const getLocoPath = (size, theme) => {
    if (size === 'big') {
      if (theme === 'theme-dark') return '/assets/images/loosid_logo_white.svg';
      return '/assets/images/loosid_logo.svg';
    }

    if (size === 'small') {
      if (theme === 'theme-dark') return '/assets/images/logo-small_white.svg';
      return '/assets/images/logo-small.svg';
    }
  };

  const showLeftMenu = (currentRoute) => {
    const routesNotAllowed = [
      RoutePaths.profile,
      RoutePaths.defaultsettings,
      RoutePaths.usersettings,
      RoutePaths.wizard
    ];
    return routesNotAllowed.includes(currentRoute) ? 'd-none' : '';
  };

  return (
    <div className="nav-header bg-white shadow-md border-0">
      <div className="nav-top w-100 justify-content-between">
        <Link to={`${Routes.dashboard}`} className="p-0 ms-0 brand-logo">
          <img
            src={getLocoPath('big', theme)}
            alt="Loosid logo"
            className="w200 d-none d-sm-block"
          />
          <img
            src={getLocoPath('small', theme)}
            alt="Loosid logo"
            className="w44 mt-1 d-block d-sm-none small"
          />
        </Link>

        <button
          onClick={toggleOpen}
          className={`nav-menu me-0 ms-auto ${buttonClass} ${showLeftMenu(
            route
          )}`}
        ></button>
        <div className="d-flex justify-content-end align-items-end sober-track-header">
          {!hideComponent(currentUser) && !wizard && (
            <div
              className="text-center me-1 menu-icon pointer"
              onClick={() => setSearchModalFlag(!searchModalIsOpen)}
            >
              <i
                className={`btn-round-lg d-flex justify-content-center align-items-center ms-2`}
              >
                <Icon icon="search" size={25} color={Colors.secondaryTitle} />
              </i>
            </div>
          )}
          <MainDropdownMenu isStaffMember={isStaffMember} />
        </div>
      </div>

      <nav
        className={`navigation scroll-bar ${navClass} ${showLeftMenu(route)}`}
      >
        <div className="container ps-0 pe-0">
          <div className="nav-content">
            <div className="nav-wrap bg-transparent-card rounded-xxl pt-3 pb-1 mt-2">
              {!hideComponent(currentUser) && (
                <div className="form-group mb-4 pb-2 px-2 mt-3">
                  <button
                    className="form-control text-center text-white fw-600 border-0 p-0 sober-track-btn d-flex align-items-center justify-content-center"
                    onClick={() => {
                      setIsOpen(false);
                      setModalFlag(true);
                    }}
                  >
                    <p className="p-0 mb-0 me-2 font-xs fw-500">
                      {'Invite Patient'}
                    </p>
                    <i
                      className={`d-flex justify-content-center align-items-center ms-2`}
                    >
                      <Icon icon="plus" size={25} color={Colors.white} />
                    </i>
                  </button>
                </div>
              )}

              <ul className={`mb-1 top-content`}>
                {sideMenuItems.map((item, index) => {
                  if (hideComponent(currentUser) && item.title !== 'Messages') {
                    return null;
                  }
                  return (
                    <li key={`nav-${index}`}>
                      <NavLink
                        to={item.route}
                        className={`nav-content-btn open-font rounded-xl ${
                          item.mainClass
                        } ${route === item.route ? 'selected' : ''}`}
                        activeClassName="custom-class"
                        onClick={() => {
                          toggleOpen();
                        }}
                      >
                        <i
                          className={`btn-round-md me-3 shadow-sm d-flex justify-content-center align-items-center`}
                        >
                          <Icon
                            icon={item.icon}
                            size={item.size}
                            color={
                              route === item.route
                                ? Colors.white
                                : Colors.blackText
                            }
                          />
                        </i>

                        <span>{item.title}</span>
                      </NavLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div className={`app-header-search ${searchClass}`}>
        <form className="search-form">
          <div className="form-group searchbox mb-0 border-0 p-1">
            <input
              type="text"
              className="form-control border-0"
              placeholder="Search..."
            />
            <i className="input-icon">
              <ion-icon
                name="search-outline"
                role="img"
                className="md hydrated"
                aria-label="search outline"
              ></ion-icon>
            </i>
            <span className="ms-1 mt-1 d-inline-block close searchbox-close">
              <i className="ti-close font-xs" onClick={toggleActive}></i>
            </span>
          </div>
        </form>
      </div>
      <ModalInvitePatient
        token={token}
        closeModal={closeModal}
        isOpenFlag={modalIsOpen}
        item={tmpPacient}
        updateMembershipCB={updatePatient}
        inviteByFileCB={uploadFile}
      />
      <ModalSearchUser
        isOpenFlag={searchModalIsOpen}
        closeModal={closeSearchModal}
        onClickItemCB={openModalUser}
      />
      <ModalUser
        closeModal={closeUserModal}
        isOpenFlag={userModalIsOpen}
        item={tmpPacient}
        getAddictionNameCB={getAddictionName}
        editPatientCB={openInviteModal}
        initChatCB={getChannel}
        getStreamClient={client}
        staffMembers={staff_members}
        openHistoryModalCB={openHistoryModal}
      />
      <ModalUserChatHistory
        closeModal={closeHistoryChatModal}
        isOpenFlag={isChatHistoryModalOpen}
        pacientID={tmpPacient}
        getStreamClient={client}
        staffMembers={staff_members}
      />
    </div>
  );
}

export default Header;
