import {
  SET_APP_THEME,
  SET_HEADER_BUTTONS,
  SET_NAVIGATION_PATH,
  SET_ACTIVE_HEADER_BTN,
  SET_ADDICTIONS,
  SET_PATIENTS,
  SET_RESERVED_MEMBERSHIPS,
  ADD_PATIENT,
  ADD_RESERVED_MEMBERSHIP
} from '../Types';

export const setThemeApp = (theme) => {
  return {
    type: SET_APP_THEME,
    payload: theme
  };
};

export const setNavigationPath = (route) => {
  return {
    type: SET_NAVIGATION_PATH,
    payload: route
  };
};

export const setActiveHeaderButton = (buttonID) => {
  return {
    type: SET_ACTIVE_HEADER_BTN,
    payload: buttonID
  };
};

export const setAddictions = (data) => {
  return {
    type: SET_ADDICTIONS,
    payload: data
  };
};

export const setListPatients = (data) => {
  return {
    type: SET_PATIENTS,
    payload: data
  };
};

export const setListReservedMemberships = (data) => {
  return {
    type: SET_RESERVED_MEMBERSHIPS,
    payload: data
  };
};

export const addPatient = (data) => {
  return {
    type: ADD_PATIENT,
    payload: data
  };
};

export const addReservedMembership = (data) => {
  return {
    type: ADD_RESERVED_MEMBERSHIP,
    payload: data
  };
};
