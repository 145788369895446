import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import Icon from '../common/Icon';
import { Colors } from '../../styles';
import InputMask from 'react-input-mask';
import {
  customStylesSmallScreen,
  customStylesBigScreen
} from '../../styles/modalsStyles';

import * as Yup from 'yup';
import './modal.scss';

const initialState = {
  phone_number: '',
  state: '',
  email: ''
};

const reservationSchema = Yup.object({
  email: Yup.string().email(),
  phone_number: Yup.string()
    .required('Phone Number is required')
    .transform((value) => (value ? value.replace(/[^\d]/g, '') : ''))
    .min(11, 'Phone Number must contain at least 11 characters'),
  state: Yup.string()
});

const ModalReservationMembership = ({
  isOpenFlag,
  closeModal,
  item,
  updateReservationCB
}) => {
  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (!item) formik.resetForm();
  }, [isOpenFlag]);

  const formik = useFormik({
    validationSchema: reservationSchema,
    initialValues: item
      ? {
          email: item.email,
          phone_number: item.phone_number,
          state: item.state
        }
      : initialState,
    onSubmit: (values) => {
      if (item) {
        let tPhone = '';
        tPhone = values.phone_number
          .replace(/ /g, '')
          .replaceAll('(', '')
          .replaceAll(')', '')
          .replaceAll('-', '');

        updateReservationCB({ ...values, phone_number: tPhone });
      }
    }
  });

  useEffect(() => {
    if (item) {
      formik.setValues({
        email: item.email,
        phone_number: item.phone_number,
        state: item.state
      });
    }
  }, [item]);

  return (
    <Modal
      isOpen={isOpenFlag}
      onRequestClose={closeModal}
      style={
        window.innerWidth <= 1024
          ? customStylesSmallScreen
          : customStylesBigScreen
      }
      ariaHideApp={false}
    >
      <div className="bg-white modal-switch-component">
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex align-items-center close-button-container">
            <i
              className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
              onClick={() => closeModal()}
            >
              <Icon icon="close" size={25} color={Colors.blackText} />
            </i>
            <h4
              className="fw-500 font-xs m-0 pointer d-none d-md-block"
              onClick={() => closeModal()}
            >
              Close
            </h4>
          </div>
          <div className="card-body p-0 d-flex justify-content-center">
            <h4 className="fw-600 font-md m-0 mt-1 pointer">Reservation</h4>
          </div>
        </div>
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex justify-content-center">
            <h4 className="fw-400 font-xs m-0 mt-4 text-center sub-point">
              Hello, edit the details of a reserved membership.
            </h4>
          </div>
        </div>
        <div className="row d-flex align-content-center">
          <div
            className={`card shadow-none border-0 ms-auto me-auto login-card pt-1 `}
          >
            <div className={`card-body rounded-0 px-0 pt-1`}>
              <form
                className="pt-2 sober-track-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group icon-input mb-3">
                  <div className="form-group icon-input mb-3">
                    <i className="d-flex">
                      <Icon icon="phone" size={23} color={Colors.greenIcon} />
                    </i>
                    <InputMask
                      name="phone_number"
                      className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                      mask="+1 (999) 999-9999"
                      maskChar=""
                      onChange={formik.handleChange}
                      value={formik.values.phone_number}
                      placeholder="Phone Number"
                    />
                    {formik.errors.phone_number &&
                      formik.touched.phone_number && (
                        <p className="p-0 mb-0 me-2 text-danger text-center">
                          {formik.errors.phone_number}.
                        </p>
                      )}
                  </div>
                </div>
                <div className="form-group icon-input mb-3">
                  <i className="d-flex">
                    <Icon icon="email" size={23} color={Colors.greenIcon} />
                  </i>
                  <input
                    disabled={formik.values.state === 'completed'}
                    id="email"
                    name="email"
                    type="email"
                    className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                    placeholder="Email"
                    onChange={formik.handleChange}
                    value={formik.values.email}
                  />
                </div>
                {formik.values.state !== 'completed' && (
                  <div className="form-group mb-1 mt-5">
                    <button
                      className="form-control text-center text-white fw-600 border-0 p-0 sober-track-btn d-flex align-items-center justify-content-center"
                      type="submit"
                    >
                      <p className="p-0 mb-0 me-2 font-xs fw-500">
                        {item ? 'Update' : 'Send Invite'}
                      </p>
                      {isLoading ? (
                        <div
                          className="spinner-border loading-indicator me-1"
                          role="status"
                        ></div>
                      ) : (
                        <i
                          className={`d-flex justify-content-center align-items-center ms-2`}
                        >
                          <Icon icon="plus" size={25} color={Colors.white} />
                        </i>
                      )}
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalReservationMembership;
