import { gql } from '@apollo/client';
import {
  PEER_USER_DATA, 
  PROFILE_FRIENDSHIP_DATA
} from './fragments'

export const FRIENDSHIP_REQUESTS = gql`
  query FetchInboxFriendshipRequests {
    currentUser {
      id
      incomingFriendshipRequests {
        id
        insertedAt
        otherUserId
        friend {
          id
        }
        requester {
          id
          firstName
          lastName
          avatarUrl
          occupation
        }
        requestMessage
        kind
        confirmedAt
        cancelledAt
      }
    }
  }
`;

export const FETCH_USER = gql`
  query GetProfileByUsername($name: ID!) {
    user: fetchUserByUsername(username: $name) {
      ...peerUserData
    }
    currentUser {
      id
      friendships{
        ...profileFriendshipData
      }
      allFriendships{
        ...profileFriendshipData
      }
    }
  }
  ${PEER_USER_DATA}
  ${PROFILE_FRIENDSHIP_DATA}
`;