import axios from 'axios';
import axiosRetry from 'axios-retry';

const connection_abort_timeout = 13000;
const request_timeout = 10000;

class Uploader {
  uploadFile = async (url, file, headers) => {
    axiosRetry(axios, { retries: 2, shouldResetTimeout: true });
    let response = null;
    let source = axios.CancelToken.source();
    this.abortTimeOut = setTimeout(() => {
      // if the response is null after timeout abort the request
      if (response === null) {
        source.cancel && source.cancel();
      }
    }, connection_abort_timeout);

    response = await axios({
      method: 'PUT',
      url: url,
      timeout: request_timeout,
      data: file,
      cancelToken: source.token,
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
      headers: headers
    })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return {
            name: 'Error',
            message: 'Request_cancelled'
          };
        }
        console.log(JSON.stringify(error));
        return JSON.stringify(error);
      })
      .finally(() => {
        if (this.abortTimeOut) clearTimeout(this.abortTimeOut);
      });
    if (response != null) {
      return response;
    } else {
      return {};
    }
  };
}

export default Uploader;
