import { USER_TOKEN, USER_DATA, SET_IS_OWNER } from '../Types';

const INITIAL_STATE = {
  token: '',
  currentUser: {},
  isOwner: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case USER_TOKEN:
      return {
        ...state,
        token: action.payload
      };
    case USER_DATA:
      return {
        ...state,
        currentUser: action.payload
      };
    case SET_IS_OWNER:
      return {
        ...state,
        isOwner: action.payload
      };
    default:
      return state;
  }
};
