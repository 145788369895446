import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Chat,
  Channel,
  MessageInput,
  MessageList,
  Window,
  Avatar
} from 'stream-chat-react';
import { setMiniChat } from '../../redux/actions/ChatActions';
import './index.scss';
import { CustomMessage } from './MessageUI';

const lightModeTheme = {
  '--second-font': '"Montserrat", sans-serif'
};

const darkModeTheme = {
  '--white-smoke': '#bababa',
  '--white-snow': '#bababa',
  '--grey-gainsboro': '##ffffff0d',
  '--dark-grey': '#1a2236'
};

const HeaderMiniChat = ({ user, onCloseCB, theme }) => {
  if (!user) return null;
  return (
    <div className="modal-popup-header w-100 border-bottom">
      <div className="card p-3 d-block border-0 d-block">
        <figure className="avatar mb-0 float-left me-2">
          <Avatar
            image={user.image}
            name={user.name}
            shape="circle"
            size={35}
          />
        </figure>
        <h5 className="fw-700 text-black font-xssss mt-1 mb-1">
          {user.channelName? user.channelName : user.name || user.id}
        </h5>
        <h4 className="text-grey-500 font-xsssss mt-0 mb-0">
          <span
            className={`${
              user.online ? 'bg-success' : 'bg-danger'
            } d-inline-block btn-round-xss m-0`}
          ></span>
          {user.online ? ' Available' : ' Unavailable'}{' '}
        </h4>
        <div
          className="font-xssss position-absolute right-0 top-0 mt-3 me-4 pointer"
          onClick={onCloseCB}
        >
          <i
            className={`ti-close ${
              theme === 'theme-light' ? 'text-grey-900' : 'text-white'
            } mt-2 d-inline-block`}
          ></i>
        </div>
      </div>
    </div>
  );
};

const MiniChat = () => {
  const {
    chat: { client, tmpUser, chatID, showChatBar },
    app: { theme }
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const disableChatInput = (channel) => {
    let disableField = true;
    for (const property in channel.state.members) {
      if (client?.userID === property) {
        disableField = false;
        break;
      }
    }

    return disableField;
  };

  return (
    <div
      className={`modal-popup-chat ${tmpUser ? 'd-block' : ''} p-0 ${
        showChatBar ? 'open' : 'closed'
      }`}
    >
      <div className="modal-popup-wrap bg-white p-0 shadow-lg rounded-3">
        <HeaderMiniChat
          user={tmpUser}
          onCloseCB={() => {
            dispatch(setMiniChat({ user: null, channel: null }));
          }}
          theme={theme}
        />
        {chatID ? (
          <Chat
            client={client}
            theme={`messaging ${
              theme === 'theme-light' ? 'light' : 'dark'
            } mini-chat`}
            customStyles={
              theme === 'theme-light' ? lightModeTheme : darkModeTheme
            }
          >
            <Channel channel={chatID}>
              <Window>
                <MessageList messageActions={[]} Message={CustomMessage} />
                <MessageInput disabled={disableChatInput(chatID)} />
              </Window>
            </Channel>
          </Chat>
        ) : (
          <div className="modal-popup-body w-100 p-3 pb-5 h-auto">
            <div
              className="snippet pt-3 ps-4 pb-0 pe-3 mt-1 bg-grey rounded-xl float-right"
              data-title=".dot-typing"
            >
              <div className="stage">
                <div className="dot-typing"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MiniChat;
