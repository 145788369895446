export const USER_TOKEN = 'USER_TOKEN';
export const THEME = 'THEME';
export const USER_DATA = 'USER_DATA';
export const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT';
export const SET_IS_OWNER = 'SET_IS_OWNER';
export const SET_CHAT_UNREAD_MESSAGES = 'SET_CHAT_UNREAD_MESSAGES';
export const SET_CHAT_LISTENER = 'SET_CHAT_LISTENER';
export const SET_APP_THEME = 'SET_APP_THEME';
export const SET_MINI_CHAT = 'SET_MINI_CHAT';
export const SET_SHOW_CHATBAR = 'SET_SHOW_CHATBAR';
export const SET_LAST_MESSAGE_TIMESTAMP = 'SET_LAST_MESSAGE_TIMESTAMP';
export const SET_STAFF_MEMBERS = 'SET_STAFF_MEMBERS';
export const SET_EXPERTS = 'SET_EXPERTS';
export const SET_CHANNEL_DELETED = 'SET_CHANNEL_DELETED';

export const SET_NAVIGATION_PATH = 'SET_NAVIGATION_PATH';
export const SET_ACTIVE_HEADER_BTN = 'SET_ACTIVE_HEADER_BTN';
export const SET_ADDICTIONS = 'SET_ADDICTIONS';
export const SET_PATIENTS = 'SET_PATIENTS';
export const SET_RESERVED_MEMBERSHIPS = 'SET_RESERVED_MEMBERSHIPS';
export const ADD_PATIENT = 'ADD_PATIENT';
export const ADD_RESERVED_MEMBERSHIP = 'ADD_RESERVED_MEMBERSHIP';
