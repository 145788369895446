import {
  SET_APP_THEME,
  SET_NAVIGATION_PATH,
  SET_ACTIVE_HEADER_BTN,
  SET_ADDICTIONS,
  SET_PATIENTS,
  SET_RESERVED_MEMBERSHIPS,
  ADD_PATIENT,
  ADD_RESERVED_MEMBERSHIP,
  SET_STAFF_MEMBERS,
  SET_EXPERTS
} from '../Types';
import ApiHandler from '../../api/api';

const INITIAL_STATE = {
  theme: 'theme-light',
  route: '',
  activeHeaderButton: '',
  addictions: [],
  listPatients: [],
  listReservedMemberships: [],
  staff_members: [],
  experts: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_APP_THEME:
      return {
        ...state,
        theme: action.payload
      };

    case SET_NAVIGATION_PATH:
      return {
        ...state,
        route: action.payload
      };
    case SET_ACTIVE_HEADER_BTN:
      return {
        ...state,
        activeHeaderButton: action.payload
      };
    case SET_ADDICTIONS:
      return {
        ...state,
        addictions: action.payload
      };
    case SET_PATIENTS:
      return {
        ...state,
        listPatients: action.payload
      };
    case SET_RESERVED_MEMBERSHIPS:
      return {
        ...state,
        listReservedMemberships: action.payload
      };
    case ADD_PATIENT:
      return {
        ...state,
        listPatients: [action.payload, ...state.listPatients]
      };
    case ADD_RESERVED_MEMBERSHIP:
      return {
        ...state,
        listReservedMemberships: [
          action.payload,
          ...state.listReservedMemberships
        ]
      };
    case SET_STAFF_MEMBERS:
      return {
        ...state,
        staff_members: action.payload
      };
    case SET_EXPERTS:
      return {
        ...state,
        experts: action.payload
      }
    default:
      return state;
  }
};

export function fetchAddictions(headers) {
  return async function getAddictions(dispatch, getState) {
    const response = await new ApiHandler().getAddictions(headers);
    if (response.status === 200) {
      dispatch({ type: SET_ADDICTIONS, payload: response.data.addictions });
    }
  };
}

export function fetchReservedMemberships(headers) {
  return async function getReservedMemberships(dispatch, getState) {
    const response = await new ApiHandler().getReservations(headers, {
      limit: 10,
      by_status: ''
    });
    if (response.status === 200) {
      dispatch({ type: SET_RESERVED_MEMBERSHIPS, payload: response.data.list });
    }
  };
}
