export const Routes = {
  demo: '/demo',
  baseRoute: '/',
  home: '/',
  changepassword: '/change-password',
  dashboard: '/dashboard',
  patients: '/patients',
  alumnievent: '/alumni-event',
  messages: '/chats',
  tasks: '/tasks',
  profile: '/profile',
  contactnow: '/contact-now',
  reservations: '/reservations',
  usersettings: '/user-settings',
  members: '/members',
  wizard: '/wizard',
  notifications: '/notifications',

  hotlines: '/hotlines',
  soberhelp: '/soberhelp',
  audio: '/audios',
  guides: '/guides',
  dating: '/dating',
  defaultbadge: '/defaultbadge',
  defaultgroup: '/defaultgroup',
  defaultstorie: '/defaultstorie',
  defaultemailbox: '/defaultemailbox',
  defaultemailopen: '/defaultemailopen',
  defaultsettings: '/defaultsettings',
  defaultvideo: '/defaultvideo',
  defaultanalytics: '/defaultanalytics',

  accountinformation: '/accountinformation',
  defaultmember: '/defaultmember',
  contactinformation: '/contactinformation',
  socialaccount: '/socialaccount',
  password: '/password',
  payment: '/payment',
  defaultnotification: '/defaultnotification',
  helpbox: '/helpbox',
  login: '/login',
  register: '/register',
  forgot: '/forgot',
  notfound: '/notfound',
  redirect: '*',

  shop1: '/shop1',
  shop2: '/shop2',
  shop3: '/shop3',
  singleproduct: '/singleproduct',
  cart: '/cart',
  checkout: '/checkout',
  defaultmessage: '/defaultmessage',
  defaultlive: '/defaultlive',

  defaultjob: '/defaultjob',
  defaultevent: '/defaultevent',
  defaulthotel: '/defaulthotel',
  grouppage: '/grouppage',
  userpage: '/userpage',
  authorpage: '/authorpage',
  comingsoon: '/comingsoon',
  defaulthoteldetails: '/defaultlive',
  post: '/post'
};
