import React from 'react';
import ReactDOM from 'react-dom';
import { Auth0Provider } from '@auth0/auth0-react';

import App from './App';
import { MyStorage } from './helpers/MyStorage';

import { Provider } from 'react-redux';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ApolloProvider } from '@apollo/client/react';

import * as serviceWorker from './serviceWorker';
import { store } from './redux/store';

const httpLink = createHttpLink({
  uri: 'https://loosid-backend-prod.herokuapp.com/api/graphql'
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = new MyStorage().getUserToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token && token !== 'null' ? `Bearer ${token}` : undefined
    }
  };
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

// const Root = () => {

//     return (
//         <ApolloProvider client={client}>

//             <Provider store={store} >

//                 <App />

//             </Provider>
//         </ApolloProvider>
//     )

// }

const Root = () => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
    >
      <ApolloProvider client={client}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>
    </Auth0Provider>
  );
};

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.register();
