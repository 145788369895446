import {
  SET_CHAT_CLIENT,
  SET_CHAT_UNREAD_MESSAGES,
  SET_CHAT_LISTENER,
  SET_MINI_CHAT,
  SET_SHOW_CHATBAR,
  SET_LAST_MESSAGE_TIMESTAMP,
  SET_CHANNEL_DELETED
} from '../Types';

const INITIAL_STATE = {
  client: null,
  unread: 0,
  listener: null,
  tmpUser: null,
  chatID: null,
  showChatBar: false,
  lastMessageTimeStamp: null,
  channelDeleted: null
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CHAT_CLIENT:
      return {
        ...state,
        client: action.payload
      };

    case SET_CHAT_UNREAD_MESSAGES:
      return {
        ...state,
        unread: action.payload
      };

    case SET_CHAT_LISTENER:
      return {
        ...state,
        listener: action.payload
      };

    case SET_MINI_CHAT:
      return {
        ...state,
        tmpUser: action.payload.user,
        chatID: action.payload.channel
      };

    case SET_SHOW_CHATBAR:
      return {
        ...state,
        showChatBar: action.payload
      };
    case SET_LAST_MESSAGE_TIMESTAMP:
      return {
        ...state,
        lastMessageTimeStamp: action.payload
      };
    case SET_CHANNEL_DELETED:
      return {
        ...state,
        channelDeleted: action.payload
      };
    default:
      return state;
  }
};
