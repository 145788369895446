import 'rc-time-picker/assets/index.css';
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import moment from 'moment';
import Uploader from '../../api/Uploader';
import { useFormik } from 'formik';
import DatePicker from 'react-date-picker';
import TimePicker from 'rc-time-picker';
import Icon from '../common/Icon';
import { Colors } from '../../styles';
import {
  customStylesSmallScreen,
  customStylesBigScreen
} from '../../styles/modalsStyles';
import * as Yup from 'yup';
import './modal.scss';

const initialState = {
  title: '',
  date: '',
  time: null,
  description: ''
};

const newInvitationSchema = Yup.object({
  title: Yup.string().required('Title is required'),
  date: Yup.date().required('Date is required'),
  time: Yup.date().typeError('Time is required').required('Time is required'),
  description: Yup.string().required('Description is required')
});

const format = 'h:mm';

const ModalAlumniEvent = ({
  isOpenFlag,
  closeModal,
  handleSubmit,
  getPresignedUrl,
  tmpEvent
}) => {
  const [isLoading, setLoading] = useState(false);
  const [timeType, setTimeType] = useState('');
  const [presignedUrl, setPresignedUrl] = useState({});
  const [selectedFile, setFile] = useState(null);
  const [imagePreviewUrl, setPreviewUrl] = useState('');
  const refDatePicker = React.useRef();
  const inputFile = React.useRef(null);

  const formik = useFormik({
    validationSchema: newInvitationSchema,
    initialValues: initialState,
    onSubmit: (values) => {
      setLoading(true);
      const submitData = async (payload) => {
        const eventDate = moment(payload.date)
          .hour(payload.time.hour())
          .minute(payload.time.minute())
          .format();
        await handleSubmit({ ...payload, event_date: eventDate });
        setLoading(false);
      };

      if (selectedFile) {
        new Uploader()
          .uploadFile(presignedUrl.url, selectedFile, {
            'x-amz-acl': 'public-read'
          })
          .then((response) => {
            if (response && response.status && response.status === 200) {
              submitData({ ...values, image_key: presignedUrl.image_key });
            } else {
              console.log('Failed to upload file: ', response);
              setLoading(false);
            }
          });
      } else {
        submitData(values);
      }
    }
  });

  const selectFile = (e) => {
    const files = e.target.files;

    if (files.length) {
      let reader = new FileReader();
      let file = e.target.files[0];

      reader.onloadend = () => {
        setFile(files[0]);
        setPreviewUrl(reader.result);
      };

      reader.readAsDataURL(file);

      if (!presignedUrl.url) {
        getPresignedUrl().then((data) => {
          console.log(data);
          setPresignedUrl(data || {});
        });
      }
    }
  };

  useEffect(() => {
    if (isOpenFlag && tmpEvent) {
      const eventDate = moment(tmpEvent.event_date);

      setPreviewUrl(tmpEvent.image_url);

      formik.setFieldValue('title', tmpEvent.title);
      formik.setFieldValue('description', tmpEvent.description);
      formik.setFieldValue('date', eventDate.toDate());
      formik.setFieldValue('time', eventDate);
      formik.setFieldValue('image_url', tmpEvent.image_url);
    } else {
      setPreviewUrl('');
      formik.resetForm();
    }

    setFile(null);
    setPresignedUrl({});
  }, [isOpenFlag]);

  return (
    <Modal
      isOpen={isOpenFlag}
      onRequestClose={closeModal}
      style={
        window.innerWidth <= 1024
          ? customStylesSmallScreen
          : customStylesBigScreen
      }
      ariaHideApp={false}
    >
      <div className="bg-white modal-switch-component">
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex align-items-center close-button-container">
            <i
              className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
              onClick={() => closeModal()}
            >
              <Icon icon="close" size={25} color={Colors.blackText} />
            </i>
            <h4
              className="fw-500 font-xs m-0 pointer d-none d-md-block"
              onClick={() => closeModal()}
            >
              Close
            </h4>
          </div>
          <div className="card-body p-0 d-flex justify-content-center">
            <h4 className="fw-600 font-md m-0 mt-1 pointer">Add Event</h4>
          </div>
        </div>

        <div className="row d-flex vh-100 align-content-center">
          <div className={`card shadow-none border-0 pt-1 align-items-center`}>
            <div className={`card-body rounded-0`}>
              <form
                className="pt-2 sober-track-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="card w-100 rounded-xxl border-0 p-0 px-3 py-2 mb-4 d-flex flex-column upload-container">
                  <div className="card-body p-0 d-flex">
                    <div className="d-flex justify-content-center preview">
                      <figure className="avatar me-0 mb-0 pointer align-self-center">
                        {imagePreviewUrl ? (
                          <img
                            src={imagePreviewUrl}
                            alt="avater"
                            className="shadow-sm w140 event-avatar"
                          />
                        ) : (
                          <i className={`feather-image`}></i>
                        )}
                      </figure>
                    </div>

                    <div className="d-flex flex-column w-100 justify-content-center ms-3">
                      <div
                        className={`d-inline-block w140 py-2 px-3 lh-28 me-3 rounded-4 pointer align-items-center st-switch-btn upload-btn`}
                        onClick={(e) => {
                          inputFile.current.click();
                        }}
                      >
                        <h4 className="fw-500 font-xs mx-auto my-0 pointer text-nowrap text-white text-center">
                          Upload
                        </h4>
                      </div>
                      <input
                        type="file"
                        id="file"
                        ref={inputFile}
                        onChange={selectFile}
                        style={{ display: 'none' }}
                      />
                      <p className="d-block instructions font-xssss fw-600 ms-2 mb-0">
                        Max size: 10 Mb
                      </p>
                    </div>
                  </div>
                </div>

                <div className="form-group icon-input mb-3">
                  <input
                    id="title"
                    name="title"
                    type="text"
                    className="style2-input ps-4 form-control text-black font-xsss fw-600 shadow-md"
                    placeholder="Event Title"
                    onChange={formik.handleChange}
                    value={formik.values.title}
                  />
                  {formik.errors.title && formik.touched.title && (
                    <p className="p-0 mb-0 me-2 text-danger text-center">
                      {formik.errors.title}.
                    </p>
                  )}
                </div>

                <div className="form-group icon-input mb-3">
                  <DatePicker
                    clearIcon={null}
                    calendarIcon={null}
                    className={`style2-input style-date-picker form-control text-black font-xsss fw-600 shadow-md ${
                      formik.values.date ? '' : 'not-show-separators'
                    }`}
                    onChange={(value) => {
                      formik.setFieldValue('date', value);
                    }}
                    value={formik.values.date}
                    yearPlaceholder=""
                    monthPlaceholder="Select a date"
                    dayPlaceholder=""
                    inputRef={refDatePicker}
                  />
                  <i
                    className={`d-flex justify-content-end align-items-center pointer right-position`}
                  >
                    <Icon
                      icon="calendar-field"
                      size={21}
                      color={Colors.blackText}
                    />
                  </i>
                  {formik.errors.date && formik.touched.date && (
                    <p className="p-0 mb-0 me-2 text-danger text-center">
                      {formik.errors.date}.
                    </p>
                  )}
                </div>

                <div className="form-group icon-input mb-3">
                  <TimePicker
                    showSecond={false}
                    className="style2-input form-control text-black font-xsss fw-600 shadow-md"
                    onChange={(value) => {
                      formik.setFieldValue('time', value);
                      setTimeType(value.format('A'));
                    }}
                    format={format}
                    use12Hours
                    inputReadOnly
                    placeholder="Add Time"
                    value={formik.values.time}
                  />
                  <div className="buttons-time">
                    <h1
                      className={`d-inline-block font-sm me-1 fw-600 btn-time ${
                        timeType === 'AM' ? 'active' : ''
                      }`}
                    >
                      AM
                    </h1>
                    <h1
                      className={`d-inline-block font-sm ms-1 fw-600 btn-time ${
                        timeType === 'PM' ? 'active' : ''
                      }`}
                    >
                      PM
                    </h1>
                  </div>
                  {formik.errors.time && formik.touched.time && (
                    <p className="p-0 mb-0 me-2 text-danger text-center">
                      {formik.errors.time}.
                    </p>
                  )}
                </div>

                <div className="form-group icon-input mb-3">
                  <textarea
                    id="description"
                    name="description"
                    type="text"
                    className="style2-textarea ps-4 py-2 pt-3 form-control text-black font-xsss fw-600 shadow-md"
                    placeholder="Event description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                  {formik.errors.description && formik.touched.description && (
                    <p className="p-0 mb-0 me-2 text-danger text-center">
                      {formik.errors.description}.
                    </p>
                  )}
                </div>
                <div className="form-group mb-1 mt-5">
                  <button
                    className="form-control text-center text-white fw-600 border-0 p-0 sober-track-btn d-flex align-items-center justify-content-center"
                    type="submit"
                  >
                    <p className="p-0 mb-0 me-2 font-xs fw-500">
                      {tmpEvent ? 'Update Now' : 'Add Now'}
                    </p>
                    {isLoading ? (
                      <div
                        className="spinner-border loading-indicator me-1"
                        role="status"
                      ></div>
                    ) : (
                      <i
                        className={`d-flex justify-content-center align-items-center ms-2`}
                      >
                        <Icon icon="plus" size={25} color={Colors.white} />
                      </i>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAlumniEvent;
