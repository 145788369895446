import {
  SET_CHAT_CLIENT,
  SET_CHAT_UNREAD_MESSAGES,
  SET_CHAT_LISTENER,
  SET_MINI_CHAT,
  SET_SHOW_CHATBAR,
  SET_LAST_MESSAGE_TIMESTAMP,
  SET_CHANNEL_DELETED
} from '../Types';

export const setClientChat = (client) => {
  return {
    type: SET_CHAT_CLIENT,
    payload: client
  };
};

export const setUnreadChatMessages = (unread) => {
  return {
    type: SET_CHAT_UNREAD_MESSAGES,
    payload: unread
  };
};

export const setChatListener = (event) => {
  return {
    type: SET_CHAT_LISTENER,
    payload: event
  };
};

export const setMiniChat = (params) => {
  return {
    type: SET_MINI_CHAT,
    payload: params
  };
};

export const setChatBarFlag = (flag) => {
  return {
    type: SET_SHOW_CHATBAR,
    payload: flag
  };
};

export const setLastMessageTimeStamp = (value) => {
  return {
    type: SET_LAST_MESSAGE_TIMESTAMP,
    payload: value
  };
};

export const setChannelDeleted = (value) => {
  return {
    type: SET_CHANNEL_DELETED,
    payload: value
  };
};
