import React, { useRef } from 'react';
import {
  Attachment,
  Avatar,
  messageHasReactions,
  MessageOptions,
  MessageRepliesCountButton,
  MessageStatus,
  MessageText,
  MessageTimestamp,
  ReactionSelector,
  SimpleReactionsList,
  useMessageContext
} from 'stream-chat-react';
import 'stream-chat-react/dist/css/index.css';
import './index.scss';
import moment from 'moment';
import Icon from '../common/Icon';
import { Colors } from '../../styles';
export const CustomMessage = () => {
  const {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
    isMyMessage
  } = useMessageContext();

  if (isMyMessage()) {
    return (
      <div
        className="str-chat__message str-chat__message--me str-chat__message-simple str-chat__message-simple--me
						str-chat__message--regular
						str-chat__message--received
						str-chat__message--has-text"
      >
        <div className="message-wrapper-content">
          {message.attachments.length === 0 && (
            <div className="talk-bubble tri-right right-top">
              <div className="talktext p-2">
                <p className="message mt-2 mx-1 text-right">{message.text}</p>
                <p className="time-message mt-0 d-flex align-items-center justify-content-end">
                  {moment(message.created_at).format('HH:mm')}
                  {message.status === 'received' && (
                    <Icon
                      icon="check"
                      size={15}
                      color={Colors.checkChatColor}
                      className="ms-2"
                    />
                  )}
                </p>
              </div>
            </div>
          )}

          {message.attachments.length > 0 && (
            <>
              <Attachment attachments={message.attachments} />
              <div className="d-flex align-items-center justify-content-end">
                <MessageTimestamp />
                {message.status === 'received' && (
                  <Icon
                    icon="check"
                    size={15}
                    color={Colors.checkChatColor}
                    className="ms-2"
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className="str-chat__message str-chat__message-simple
						str-chat__message--regular
						str-chat__message--received
						str-chat__message--has-text"
    >
      <div className="chat-avatar">
        <Avatar image={message.user?.image} />
      </div>
      <div className="message-wrapper-content">
        {message.attachments.length === 0 && (
          <div className="talk-bubble tri-right left-top">
            <div className="talktext p-2">
              <p className="user">{message.user?.name}</p>
              <p className="message mt-2">{message.text}</p>
              <p className="time-message mt-0 d-flex align-items-center justify-content-end">
                {moment(message.created_at).format('HH:mm')}
              </p>
            </div>
          </div>
        )}

        {message.attachments.length > 0 && (
          <div className="ms-4 ps-4">
            <p className="user">{message.user?.name}</p>
            <Attachment attachments={message.attachments} />
            <div className="d-flex align-items-center justify-content-end pe-2">
              <MessageTimestamp />
            </div>
          </div>
        )}
        <MessageRepliesCountButton reply_count={message.reply_count} />
      </div>
    </div>
  );
};
