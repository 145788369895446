import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Modal from 'react-modal';
import { useAuth0 } from '@auth0/auth0-react';
import Icon from '../common/Icon';
import { CustomMessage } from '../../components/MiniChat/MessageUI';
import { Colors } from '../../styles';
import './modal.scss';
import '../../pages/Chat/index.scss';
import { Load } from '..';
import {
  Chat,
  Channel,
  MessageInput,
  MessageList,
  Window,
  Avatar
} from 'stream-chat-react';
import {
  customStylesSmallScreen,
  customLeftStylesBigScreen as customStylesBigScreen
} from '../../styles/modalsStyles';
import { defaultAvatar } from '../../helpers/Util';
import loadable from '@loadable/component';
import { GetStreamChatID } from '../../config/constants';

const ChatItem = loadable(() => import('../common/Chat/ChatItem'));

const INITIAL_QUERY_PARAMS = {
  ascending: false,
  order: 'default',
  page: 1
};

const lightModeTheme = {
  '--second-font': '"Montserrat", sans-serif'
};

const ModalUserChatHistory = ({
  isOpenFlag,
  closeModal,
  pacientID,
  getStreamClient,
  staffMembers
}) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const getStreamUserID = user.sub.replace('auth0|', 'auth0_');
  const [channels, setChannels] = useState([]);
  const [coversation, setConversation] = useState(false);
  const [chatID, setChatID] = useState(null);
  const [userChat, setUserChat] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (getStreamClient?.user?.role === 'moderator' && pacientID) {
      fetchUsersChannel(pacientID?.external_id);
    }
  }, [getStreamClient, pacientID]);

  const fetchUsersChannel = async (userID) => {
    let members_ids = staffMembers.map((item) => item.auth0_id);
    if (!members_ids.includes(getStreamUserID)) {
      members_ids.push(getStreamUserID);
    }

    const filter = {
      type: GetStreamChatID,
      members: { $in: [userID] }
    };

    const sort = [{ last_message_at: -1 }];

    const channels = await getStreamClient.queryChannels(filter, sort, {
      watch: true, // this is the default
      state: true
    });

    if (channels.length > 0) {
      const filter = channels.filter((ch) => ch.state.messages.length);
      setChannels(filter);
    }
    setLoading(false);
  };

  const closeConversation = () => {
    setChatID(null);
    setUserChat(null);
    setConversation(false);
  };

  const openConversation = (user, channel) => {
    setConversation(true);
    setChatID(channel);
    setUserChat(user);
  };

  const disableChatInput = (chat) => {
    let disableField = true;
    for (const property in chat.state.members) {
      if (getStreamUserID === property) {
        disableField = false;
        break;
      }
    }

    return disableField;
  };

  if (getStreamClient?.user?.role !== 'moderator') {
    return null;
  }

  return (
    <Modal
      isOpen={isOpenFlag}
      onRequestClose={closeModal}
      style={customStylesSmallScreen}
      ariaHideApp={false}
    >
      <div className="bg-white modal-switch-component">
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex align-items-center close-button-container">
            <i
              className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
              onClick={() => {
                closeConversation();
                closeModal();
              }}
            >
              <Icon icon="close" size={25} color={Colors.blackText} />
            </i>
            <h4
              className="fw-500 font-xs m-0 pointer d-none d-md-block"
              onClick={() => {
                closeConversation();
                closeModal();
              }}
            >
              Close
            </h4>
          </div>
          <div className="card-body p-0 d-flex justify-content-center">
            <h4 className="fw-600 font-md m-0 mt-1 pointer">
              Chats with {pacientID?.first_name} {pacientID?.last_name}
            </h4>
          </div>
        </div>
        <div className="row p-3">
          <div
            className={`${
              coversation ? 'd-block' : 'd-none'
            } col-8 px-0 bg-white rounded-xl shadow `}
          >
            {chatID ? (
              <div className="main-chat-container">
                <div className="d-flex p-2 border border-top-0 border-end-0 border-start-0">
                  <div className="card-body p-0 d-flex align-items-center">
                    <div className="card-body p-0 d-flex">
                      <Avatar
                        image={userChat.image || defaultAvatar}
                        shape="circle"
                        size={45}
                      />
                      <h4 className="fw-600 font-xsss my-auto name-user pointer">
                        {userChat.name}
                      </h4>
                    </div>
                    <i
                      className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
                      onClick={() => closeConversation()}
                    >
                      <Icon icon="close" size={25} color={Colors.blackText} />
                    </i>
                  </div>
                </div>
                <Chat
                  client={getStreamClient}
                  theme={`messaging light`}
                  customStyles={lightModeTheme}
                >
                  <Channel channel={chatID}>
                    <Window>
                      <MessageList
                        messageActions={[]}
                        Message={CustomMessage}
                      />
                      <MessageInput disabled={disableChatInput(chatID)} />
                    </Window>
                  </Channel>
                </Chat>
              </div>
            ) : null}
          </div>
          <div
            className={`${
              coversation
                ? 'col-4 compact-view px-2'
                : 'col-12 ps-lg-3 pb-4 pe-lg-3'
            }  `}
          >
            {loading && <Load />}
            <div className="row px-3">
              {channels.map((item) => {
                return (
                  <ChatItem
                    chat={item}
                    key={item.id}
                    onClickCB={openConversation}
                    currentUserID={getStreamUserID}
                    showShadow={coversation ? false : true}
                    staffMembers={staffMembers}
                    getStreamUser={getStreamClient?.user}
                    moderatorMode={true}
                    individualChats={true}
                  />
                );
              })}

              {channels.length === 0 && (
                <div className="card-body p-0 mt-4 d-flex justify-content-center">
                  <h2 className="text-center mt-4 font-md">
                    No conversations found.
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalUserChatHistory;
