import React from 'react';

const Load = (cardWrapped = true) => {

    if (cardWrapped) {
        return (
            <div className="card w-100 text-center shadow-xss rounded-xxl border-0 p-4 mb-3 mt-3">
                <div className="snippet mt-2 ms-auto me-auto" data-title=".dot-typing">
                    <div className="stage">
                        <div className="dot-typing"></div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="text-center rounded-xxl border-0 p-4">
            <div className="mt-2 d-flex justify-content-center" data-title=".dot-typing">
                <div className="stage">
                    <div className="dot-typing"></div>
                </div>
            </div>
        </div>
    );
}

export default Load;