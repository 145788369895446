import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import Icon from '../common/Icon';
import { Colors } from '../../styles';
import Select from 'react-select';
import {
  customStylesSmallScreen,
  customStylesBigScreen
} from '../../styles/modalsStyles';

import * as Yup from 'yup';
import './modal.scss';

const initialState = {
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  is_admin: false,
  phone: '',
  status: true,
  type_form: 'create',
  auth0_id: '',
  receiving_calls: true
};

const customStyles = {
  container: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    background: '#FFFFFF',
    width: '100%',
    display: 'inline-block',
    borderRadius: '20px',
    height: '58px',
    borderColor: 'transparent'
  }),
  control: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    // background: '#000000',
    // borderColor: '#000000',
    borderColor: 'transparent',
    borderRadius: '20px',
    color: 'red',
    '&:hover': {
      // borderColor: Colors.greenIcon
    },
    boxShadow: 'none',
    color: '#000000',
    height: '58px'
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none'
  }),
  input: (styles) => ({
    ...styles,
    color: '#000000',
    textAlign: 'left',
    borderColor: 'transparent'
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#000000',
    textAlign: 'left',
    fontWeight: '600',
    fontSize: '14px',
    paddingLeft: '10px',
    borderColor: 'transparent'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingRight: '15px',
    paddingLeft: 0
  }),
  valueContainer: (styles) => ({
    ...styles,
    paddingRight: 0
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 })
};

const optionsState = [
  { value: true, label: 'Active' },
  { value: false, label: 'Disabled' }
];

const optionsReceivingCalls = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const staffMemberSchema = Yup.object({
  email: Yup.string()
    .email()
    .when('type_form', (type_form) => {
      if (type_form === 'create')
        return Yup.string().required('Email is required.');
    }),
  first_name: Yup.string().required('First name is required.'),
  last_name: Yup.string().required('Last name is required.'),
  password: Yup.string().when('type_form', (type_form) => {
    if (type_form === 'create')
      return Yup.string().required('Password is required.');
  }),
  reservation_type: Yup.string(),
  phone: Yup.string()
    .transform((value) => (value ? value.replace(/[^\d]/g, '') : ''))
    .min(11, 'Phone Number must contain at least 11 characters')
    .required('Phone Number is required.'),
  type_form: Yup.string(),
  status: Yup.boolean().required(),
  is_admin: Yup.boolean().when('type_form', (type_form) => {
    if (type_form === 'create')
      return Yup.boolean().required('Is Admin is required.');
  })
});

const ModalStaffMember = ({ isOpenFlag, closeModal, item, onSubmitCB }) => {
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    validationSchema: staffMemberSchema,
    initialValues: item
      ? {
          first_name: item.first_name,
          last_name: item.last_name,
          phone: item.phone,
          status: item.status,
          is_admin: false,
          receiving_calls: item.receiving_calls,
          auth0_id: item.auth0_id
        }
      : initialState,
    onSubmit: (values) => {
      let tPhone = '';
      tPhone = values.phone
        .replace(/ /g, '')
        .replaceAll('(', '')
        .replaceAll(')', '')
        .replaceAll('-', '');

      setLoading(true);
      onSubmitCB({ ...values, phone: tPhone }).then(() => {
        setLoading(false);
        closeModal();
        formik.resetForm();
      });
      // if (item) {
      //   updateMembersCB({ ...values, phone: tPhone }).then(() => {
      //     setLoading(false);
      //     closeModal();
      //     formik.resetForm();
      //   });
      // } else {
      //   console.log('create member', { ...values, phone: tPhone });
      //   // updateMembersCB({ ...values, phone_number: tPhone });
      // }
    }
  });

  useEffect(() => {
    if (!item) formik.resetForm();
  }, [isOpenFlag]);

  useEffect(() => {
    if (item) {
      formik.setValues({
        first_name: item.first_name,
        last_name: item.last_name,
        phone: item.phone,
        status: item.status,
        receiving_calls: item.receiving_calls,
        auth0_id: item.auth0_id,
        type_form: 'update'
      });
    }
  }, [item]);

  return (
    <Modal
      isOpen={isOpenFlag}
      onRequestClose={closeModal}
      style={
        window.innerWidth <= 1024
          ? customStylesSmallScreen
          : customStylesBigScreen
      }
      ariaHideApp={false}
    >
      <div className="bg-white modal-switch-component">
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex align-items-center close-button-container">
            <i
              className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
              onClick={() => closeModal()}
            >
              <Icon icon="close" size={25} color={Colors.blackText} />
            </i>
            <h4
              className="fw-500 font-xs m-0 pointer d-none d-md-block"
              onClick={() => closeModal()}
            >
              Close
            </h4>
          </div>

          <div className="card-body p-0 d-flex justify-content-center">
            <h4 className="fw-600 font-md m-0 mt-1 pointer">
              {item ? 'Update ' : 'Create '}Staff Member
            </h4>
          </div>
        </div>
        {/* <div className="d-flex p-3">
          <div className="card-body p-0 d-flex justify-content-center">
            {item ? (
              <h4 className="fw-400 font-xs m-0 mt-4 text-center sub-point">
                Hello, You can invite your Patients <br /> to track their
                sobriety
              </h4>
            ) : (
              <h4 className="fw-400 font-xs m-0 mt-2 text-center sub-point">
                Hello, you can reserve memberships for your Patients
              </h4>
            )}
          </div>
        </div> */}
        <div className="row d-flex align-content-center">
          <div
            className={`card shadow-none border-0 ms-auto me-auto login-card pt-1 `}
          >
            <div className={`card-body rounded-0 px-0 pt-1`}>
              <form
                className="pt-2 sober-track-form"
                onSubmit={formik.handleSubmit}
              >
                {!item && (
                  <div className="form-group icon-input mb-3">
                    <i className="d-flex">
                      <Icon icon="email" size={23} color={Colors.greenIcon} />
                    </i>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                      placeholder="Email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                    />
                    {formik.errors.email && formik.touched.email && (
                      <p className="p-0 mb-0 me-2 text-danger text-center">
                        {formik.errors.email}.
                      </p>
                    )}
                  </div>
                )}
                {!item && (
                  <div className="form-group icon-input mb-3">
                    <i className="d-flex">
                      <Icon icon="email" size={23} color={Colors.greenIcon} />
                    </i>
                    <input
                      id="password"
                      name="password"
                      type="password"
                      className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                      placeholder="Password"
                      onChange={formik.handleChange}
                      value={formik.values.password}
                    />
                    {formik.errors.password && formik.touched.password && (
                      <p className="p-0 mb-0 me-2 text-danger text-center">
                        {formik.errors.password}.
                      </p>
                    )}
                  </div>
                )}
                <div className="form-group icon-input mb-3">
                  {item && (
                    <label className="fw-600 font-xsss text-grey-900 ms-2 ps-2 pb-1">
                      First Name
                    </label>
                  )}
                  <div className="form-group icon-input mb-3">
                    <i className="d-flex">
                      <Icon icon="sponsor" size={23} color={Colors.greenIcon} />
                    </i>
                    <input
                      id="first_name"
                      name="first_name"
                      type="text"
                      className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                      placeholder="First Name"
                      onChange={formik.handleChange}
                      value={formik.values.first_name}
                    />
                    {formik.errors.first_name && formik.touched.first_name && (
                      <p className="p-0 mb-0 me-2 text-danger text-center">
                        {formik.errors.first_name}.
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group icon-input mb-3">
                  {item && (
                    <label className="fw-600 font-xsss text-grey-900 ms-2 ps-2 pb-1">
                      Last Name
                    </label>
                  )}
                  <div className="form-group icon-input mb-3">
                    <i className="d-flex">
                      <Icon icon="sponsor" size={23} color={Colors.greenIcon} />
                    </i>
                    <input
                      id="last_name"
                      name="last_name"
                      type="text"
                      className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                      placeholder="Last Name"
                      onChange={formik.handleChange}
                      value={formik.values.last_name}
                    />
                    {formik.errors.last_name && formik.touched.last_name && (
                      <p className="p-0 mb-0 me-2 text-danger text-center">
                        {formik.errors.last_name}.
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-group icon-input mb-3">
                  {item && (
                    <label className="fw-600 font-xsss text-grey-900 ms-2 ps-2 pb-1">
                      Phone
                    </label>
                  )}
                  <div className="form-group icon-input mb-3">
                    <i className="d-flex">
                      <Icon icon="phone" size={23} color={Colors.greenIcon} />
                    </i>
                    <InputMask
                      name="phone"
                      className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                      mask="+1 (999) 999-9999"
                      maskChar=""
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      placeholder="Phone Number"
                    />
                    {formik.errors.phone && formik.touched.phone && (
                      <p className="p-0 mb-0 me-2 text-danger text-center">
                        {formik.errors.phone}.
                      </p>
                    )}
                  </div>
                </div>
                {item && (
                  <div className="form-group icon-input mb-3">
                    <label className="fw-600 font-xsss text-grey-900 ms-2 ps-2 pb-1">
                      Status
                    </label>
                    <Select
                      className="shadow-md"
                      styles={customStyles}
                      name="status"
                      defaultValue={optionsState.find(
                        (option) => option.value === formik.values.status
                      )}
                      options={optionsState}
                      placeholder="Status of the member"
                      onChange={(e) => {
                        formik.setFieldValue('status', e.value);
                      }}
                    />
                  </div>
                )}

                <div className="form-group icon-input mb-3">
                  <label className="fw-600 font-xsss text-grey-900 ms-2 ps-2 pb-1">
                    Receiving Calls
                  </label>
                  <Select
                    className="shadow-md"
                    styles={customStyles}
                    name="receiving_calls"
                    defaultValue={optionsReceivingCalls.find(
                      (option) => option.value === formik.values.receiving_calls
                    )}
                    options={optionsReceivingCalls}
                    placeholder="Receiving Calls"
                    onChange={(e) => {
                      formik.setFieldValue('receiving_calls', e.value);
                    }}
                  />
                </div>
                {!item && (
                  <div className="form-group icon-input mb-3">
                    <label className="fw-600 font-xsss text-grey-900 ms-2 ps-2 pb-1">
                      is Admin?
                    </label>
                    <Select
                      className="shadow-md"
                      styles={customStyles}
                      name="is_admin"
                      defaultValue={optionsReceivingCalls.find(
                        (option) => option.value === formik.values.is_admin
                      )}
                      options={optionsReceivingCalls}
                      placeholder="is Admin?"
                      onChange={(e) => {
                        formik.setFieldValue('is_admin', e.value);
                      }}
                    />
                  </div>
                )}

                <div className="form-group mb-1 mt-5">
                  <button
                    className="form-control text-center text-white fw-600 border-0 p-0 sober-track-btn d-flex align-items-center justify-content-center"
                    type="submit"
                  >
                    <p className="p-0 mb-0 me-2 font-xs fw-500">
                      {item ? 'Update' : 'Create'}
                    </p>
                    {isLoading ? (
                      <div
                        className="spinner-border loading-indicator me-1"
                        role="status"
                      ></div>
                    ) : (
                      <i
                        className={`d-flex justify-content-center align-items-center ms-2`}
                      >
                        <Icon icon="plus" size={25} color={Colors.white} />
                      </i>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalStaffMember;
