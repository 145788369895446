export const DiscoursePostsHome = 'https://forum.loosid.cc';
export const DiscourseServer = 'https://adiscourse01.loosid.cc';
export const HotlinePostsHome = 'https://forum.loosid.cc/c/hotline';
export const GetStreamInstance_DEV = 'vsjpbrw8srpc';
export const GetStreamInstance_PROD = 'jr2cyme8kkr9';
export const GetStreamChatID = 'sam-center';
export const ExpertsChatID = 'experts';
export const SUPPORT_USER_ID = 'auth0_62f3d47373367955412d862d';
export const SUPPORT_CONVERSATION = 'support';
export const EXPERT_CONVERSATION = 'experts';
export const JOSH_EXPERT_ACCOUNT = 'auth0_630945d8a2e72283312b7534';
