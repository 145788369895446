import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setMiniChat,
  setChannelDeleted
} from '../../redux/actions/ChatActions';
import 'stream-chat-react/dist/css/index.css';
import Icon from '../common/Icon';
import { Colors } from '../../styles';
import loadable from '@loadable/component';
import { Load } from '..';
import { useAuth0 } from '@auth0/auth0-react';
import { GetStreamChatID, ExpertsChatID } from '../../config/constants';
import { useChatContext } from 'stream-chat-react';

import './index.scss';

const ChatItem = loadable(() => import('../common/Chat/ChatItem'));
const LIMIT_CHANNELS = 20;

const MessagesBar = () => {
  const [state, setState] = useState({
    height: 182,
    isOpen: false
  });
  const [loading, setLoading] = useState(true);
  const [loadingChannels, setLoadingChannels] = useState(true);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [channels, setChannels] = useState([]);
  const [channelOffset, setChannelOffset] = useState(0);
  const {
    chat: { client, lastMessageTimeStamp, channelDeleted, chatID },
    app
  } = useSelector((state) => state);
  const { user } = useAuth0();
  const getStreamUserID = user.sub.replace('auth0|', 'auth0_');
  const observer = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (client) {
      setLoading(false);
      setChannelOffset(0);
      fetchChannels(true);
    }
  }, [lastMessageTimeStamp]);

  useEffect(() => {
    if (client) {
      fetchChannels();
    }
  }, [client]);

  useEffect(() => {
    if (channelDeleted) {
      if (channelDeleted === chatID?.cid) {
        dispatch(setMiniChat({ user: null, channel: null }));
      }
      fetchChannels(true);
      dispatch(setChannelDeleted(null));
    }
  }, [channelDeleted]);

  const fetchChannels = async (refresh = false) => {
    setLoadingChannels(true);
    let members_ids = app.staff_members.map((item) => item.auth0_id);
    if (!members_ids.includes(getStreamUserID)) {
      members_ids.push(getStreamUserID);
    }
    const experts_ids = app.experts.map((item) => item.external_id);
    const filter_members =
      client?.user?.role === 'moderator'
        ? [...members_ids, ...experts_ids]
        : [getStreamUserID];
    const type_conversation =
      client?.user?.role === 'moderator'
        ? [GetStreamChatID, ExpertsChatID]
        : [GetStreamChatID];

    const filter = {
      type: { $in: type_conversation },
      members: { $in: filter_members }
    };

    const sort = [{ last_message_at: -1 }];

    const chats_channels = await client.queryChannels(filter, sort, {
      watch: true, // this is the default
      state: true,
      limit: LIMIT_CHANNELS,
      offset: refresh ? 0 : channelOffset
    });

    setHasMoreData(chats_channels.length === LIMIT_CHANNELS);
    setChannelOffset(channelOffset + LIMIT_CHANNELS);
    if (refresh) {
      setChannels(chats_channels);
    } else {
      channels.length > 0
        ? setChannels([...channels, ...chats_channels])
        : setChannels(chats_channels);
    }

    setLoading(false);
    setLoadingChannels(false);
  };

  const openMiniChat = (user, channel, channelName) => {
    user.channelName = channelName;

    dispatch(setMiniChat({ user, channel }));
  };

  const lastElement = useCallback((node) => {
    if (loadingChannels) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMoreData) {
        fetchChannels();
      }
    });
    if (node) observer.current.observe(node);
  });

  return (
    <div
      className="section mx-2 p-3 mt-4 bg-white rounded-xxl right-chat-list"
      style={{ height: `${window.innerHeight - 150}px` }}
    >
      <>
        <div className="card-body px-0 pt-0 d-flex align-items-center">
          <h1 className="font-md fw-600 secondaryTitle mb-0">Inbox</h1>
          <i
            className={`d-flex justify-content-center align-items-center ms-auto pointer`}
          >
            <Icon icon="inbox" size={30} color={Colors.blackText} />
          </i>
        </div>
      </>
      {loading && <Load />}

      <div className="row px-3 px-lg-0">
        {channels.map((item, i) => {
          const isLastElement = channels.length === i + 1;
          if (isLastElement) {
            return (
              <div ref={lastElement} key={`${item.id}`} className="p-0">
                <ChatItem
                  chat={item}
                  key={item.id}
                  onClickCB={openMiniChat}
                  currentUserID={getStreamUserID}
                  getStreamUser={client.user}
                  staffMembers={app.staff_members}
                  moderatorMode={true}
                  individualChats={false}
                />
              </div>
            );
          }
          // console.log(item);
          return (
            <ChatItem
              chat={item}
              key={item.id}
              onClickCB={openMiniChat}
              currentUserID={getStreamUserID}
              getStreamUser={client.user}
              staffMembers={app.staff_members}
              moderatorMode={true}
              individualChats={false}
            />
          );
        })}
        {channels.length === 0 && !loading && (
          <h1 className="fw-500 font-xssss ms-1 my-2 text-center">
            {' '}
            No conversations found.
          </h1>
        )}
      </div>
    </div>
  );
};

export default MessagesBar;
