export const dialNumber = (number) => {
  window.open(`tel:${number}`, '_self');
};

export const openExternalLink = (link) => {
  window.open(link);
};

export const getHeaders = (token) => {
  return {
    authorization: `Bearer ${token}`,
    'X-Origin': 'webportal',
    'Content-Type': 'application/json'
  };
};

export const getFeelingLabel = (level) => {
  switch (level) {
    case 20: {
      return 'Sad';
    }

    case 30: {
      return 'Streesed';
    }

    case 40: {
      return 'Scared';
    }

    case 80: {
      return 'Relaxed';
    }

    case 90: {
      return 'Confident';
    }

    case 100: {
      return 'Happy';
    }

    default: {
      return '';
    }
  }
};

export const getAlertMessage = (message_id) => {
  const messages = [
    'Hey! Just wanted to day hi and wishing you a great day!',
    'Hey, I am a little down today. Do you have some time to talk?',
    'I need your help. I feel like using. Please contact me.'
  ];

  return message_id ? messages[Number(message_id - 1)] : '';
};

export const defaultAvatar = '/assets/images/AvatarPhoto.png';

export const hideComponent = (currentUser) => {
  if (currentUser.is_support || currentUser.is_expert) {
    return true;
  }

  return false;
};
