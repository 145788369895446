import React, { useState, useCallback, useRef } from 'react';
import Modal from 'react-modal';
import { useAuth0 } from '@auth0/auth0-react';
import ApiHandler from '../../api/api';
import Icon from '../common/Icon';
import { Colors } from '../../styles';
import './modal.scss';
import { Load } from '..';
import { getHeaders } from '../../helpers/Util';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  customStylesSmallScreen,
  customLeftStylesBigScreen as customStylesBigScreen
} from '../../styles/modalsStyles';
import { defaultAvatar } from '../../helpers/Util';

const INITIAL_QUERY_PARAMS = {
  ascending: false,
  order: 'default',
  page: 1
};

const initialState = {
  search: ''
};

const newInvitationSchema = Yup.object({
  search: Yup.string().required('The search field cannot be empty.')
});

const ModalSearchUser = ({ isOpenFlag, closeModal, onClickItemCB }) => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(false);
  const [paramsQuery, setParamsQuery] = useState(INITIAL_QUERY_PARAMS);
  const [noResults, setNoResults] = useState(false);
  const observer = useRef();

  const { getAccessTokenSilently } = useAuth0();

  const getPatients = async ({ searchTerm, initialLoad = false }) => {
    setLoading(true);
    setNoResults(false);
    const currentToken = await getAccessTokenSilently();

    const page = initialLoad ? 1 : paramsQuery.page;
    const offset = page * 10 - 10;
    let queryParams = {
      by_name: searchTerm,
      offset: offset,
      by_membership_status: 'active'
    };

    if (initialLoad) {
      setResults([]);
      setLoading(true);
      setParamsQuery(INITIAL_QUERY_PARAMS);
    }

    const tmpArrayPatients = initialLoad ? [] : results;

    new ApiHandler()
      .getPatients(getHeaders(currentToken), queryParams)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          if (initialLoad && response.data.memberships.length === 0) {
            setNoResults(true);
          }
          setResults([...tmpArrayPatients, ...response.data.memberships]);
          setParamsQuery({ ...paramsQuery, page: paramsQuery.page + 1 });
          setHasMoreData(response.data.memberships.length < 10 ? false : true);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const formik = useFormik({
    validationSchema: newInvitationSchema,
    initialValues: initialState,
    onSubmit: (values) => {
      getPatients({ searchTerm: values.search, initialLoad: true });
    }
  });

  const lastElement = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMoreData) {
        getPatients({ initialLoad: false, searchTerm: formik.values.search });
      }
    });
    if (node) observer.current.observe(node);
  });

  const clickOnItem = (item) => {
    onClickItemCB(item);
    formik.resetForm();
    setResults([]);
  };

  const ItemPatient = ({ item, onClickCB }) => {
    return (
      <div className="card w-100 border-0 shadow p-3 rounded-xxl mb-3">
        <div className="card-body p-0">
          <div className="row align-items-center">
            <div className="col-12 p-2 p-sm-0 pe-2 ps-sm-3">
              <div className="card-body p-0 d-flex">
                <figure
                  className="avatar me-2 mb-0 pointer"
                  onClick={() => onClickCB(item)}
                >
                  {item.status === 'active' ? (
                    <img
                      src={defaultAvatar}
                      alt="avater"
                      className="rounded-circle w60"
                    />
                  ) : (
                    <i
                      className={`d-flex justify-content-center align-items-center btn-round-xl default-avatar`}
                    >
                      <Icon
                        icon={'sponsor'}
                        size={25}
                        color={Colors.greenIcon}
                      />
                    </i>
                  )}
                </figure>
                <h4
                  className="fw-600 font-xsss my-auto name-user pointer"
                  onClick={() => onClickCB(item)}
                >
                  {item.full_name
                    ? item.full_name
                    : `${item.first_name} ${item.last_name}`}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpenFlag}
      onRequestClose={closeModal}
      style={
        window.innerWidth <= 1024
          ? customStylesSmallScreen
          : customStylesBigScreen
      }
      ariaHideApp={false}
    >
      <div className="bg-white modal-switch-component">
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex align-items-center close-button-container">
            <i
              className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
              onClick={() => closeModal()}
            >
              <Icon icon="close" size={25} color={Colors.blackText} />
            </i>
            <h4
              className="fw-500 font-xs m-0 pointer d-none d-md-block"
              onClick={() => closeModal()}
            >
              Close
            </h4>
          </div>
          <div className="card-body p-0 d-flex justify-content-center">
            <h4 className="fw-600 font-md m-0 mt-1 pointer">Search Patient</h4>
          </div>
        </div>
        <div className="row d-flex align-content-center">
          <div className={`card shadow-none border-0 ms-auto me-auto pt-1 `}>
            <div className={`card-body rounded-0`}>
              <form
                className="pt-2 sober-track-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group icon-input mb-3">
                  <i className="d-flex">
                    <Icon icon="search" size={23} color={Colors.greenIcon} />
                  </i>
                  <input
                    id="search"
                    name="search"
                    type="text"
                    className="style2-input ps-5 form-control text-black font-xsss fw-600 shadow-md"
                    placeholder="Type a name"
                    onChange={formik.handleChange}
                    value={formik.values.search}
                  />
                  {formik.errors.search && formik.touched.search && (
                    <p className="p-0 mb-0 me-2 text-danger text-center">
                      {formik.errors.search}.
                    </p>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        {loading && <Load />}
        {results.length > 0 ? (
          <div className="row patients-data px-3 px-lg-0">
            <div className={`card shadow-none border-0 ms-auto me-auto pt-1 `}>
              <div className={`card-body rounded-0 pt-0`}>
                {results.map((item, i) => {
                  const isLastElement = results.length === i + 1;
                  if (isLastElement) {
                    return (
                      <div
                        ref={lastElement}
                        key={`mmbsh-${item.id}`}
                        className="p-0"
                      >
                        <ItemPatient item={item} onClickCB={clickOnItem} />
                      </div>
                    );
                  }
                  return (
                    <ItemPatient
                      item={item}
                      key={`mmbsh-${item.id}`}
                      onClickCB={clickOnItem}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {noResults ? (
              <h2 className="text-center mt-2 font-md">There is no results</h2>
            ) : null}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalSearchUser;
