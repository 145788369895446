import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Routes as RoutePaths, Routes } from '../routing/Routes';
import Icon from './common/Icon';
import { Colors } from '../styles';
import { defaultAvatar } from '../helpers/Util';

const Appfooter = () => {
  const {
    user: { currentUser }
  } = useSelector((state) => state);

  return (
    <div className="app-footer border-0 shadow-lg bg-primary-gradiant">
      <Link to={RoutePaths.dashboard} className="nav-content-bttn nav-center">
        <Icon icon="home" size={25} color={Colors.white} />
      </Link>
      <Link to={RoutePaths.patients} className="nav-content-bttn nav-center">
        <Icon icon="patients" size={30} color={Colors.white} />
      </Link>
      <Link to={RoutePaths.messages} className="nav-content-bttn nav-center">
        <Icon icon="message-empty" size={25} color={Colors.white} />
      </Link>
      <Link to={RoutePaths.alumnievent} className="nav-content-bttn nav-center">
        <Icon icon="alumni-event" size={25} color={Colors.white} />
      </Link>
      <Link
        to={RoutePaths.defaultsettings}
        className="nav-content-bttn nav-center"
      >
        {currentUser.image ? (
          <img
            src={currentUser.image || defaultAvatar}
            alt="user"
            className="w40 shadow-sm rounded-circle"
          />
        ) : (
          <Icon icon="sponsor" size={25} color={Colors.white} />
        )}
      </Link>
    </div>
  );
};

export default Appfooter;
