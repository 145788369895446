import PropTypes from 'prop-types';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ErrorMsg(errMsg) {
  toast.error(errMsg, {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
}

ErrorMsg.propTypes = {
  errMsg: PropTypes.string.isRequired
};

export default ErrorMsg;
