export const customStylesSmallScreen = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
      zIndex: 1000,
      padding: '0px'
    },
    content: {
      height: '100%',
      width: '100%',
      inset: 'unset',
      border: 'none',
      padding: '0px'
    }
  };

export const customStylesBigScreen = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
      zIndex: 1000,
      padding: '0px'
    },
    content: {
      left: 'auto',
      right: '50%',
      top: '50%',
      height: '100%',
      width: '50%',
      inset: '',
      border: 'none',
      padding: '0px'
    }
  };

  export const customLeftStylesBigScreen = {
    overlay: {
      backgroundColor: 'rgba(0,0,0,0.7)',
      zIndex: 1000,
      padding: '0px'
    },
    content: {
      right: 'auto',
      left: '50%',
      top: '50%',
      height: '100%',
      width: '50%',
      marginLeft: '25%',
      transform: 'translate(-50%, -50%)',
      border: 'none',
      padding: '0px'
    }
  };