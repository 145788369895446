import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../common/Icon';
import { Colors } from '../../styles';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './index.scss';

const TIMER = 5000;

const CustomTitle = ({ message }) => {
  const [seconds, setSeconds] = React.useState(TIMER / 1000);

  React.useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds('');
    }
  });
  return (
    <div className="notification p-0">
      <Icon color={Colors.white} size={25} icon="task" className="mb-2" />
      <h1 className="font-xss">{message}</h1>
      <div className="buttons-bottom d-flex justify-content-between align-items-center pt-2">
        <div
          className={`d-flex py-2 px-3 lh-28 me-3 text-center font-xss fw-600 rounded-3 pointer align-items-center justify-content-center notification-btn`}
        >
          <h4 className="fw-500 font-xsss m-0 pointer text-nowrap">Got it</h4>
        </div>

        <div className="Toastify__spinner"></div>
        <div className="time-countdown">{seconds}</div>
      </div>
    </div>
  );
};

function SuccessMsg(successMsg) {
  toast.success(<CustomTitle message={successMsg} />, {
    position: 'top-right',
    autoClose: TIMER,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    closeButton: false,
    icon: false
  });
}

SuccessMsg.propTypes = {
  successMsg: PropTypes.string.isRequired
};

export default SuccessMsg;
