import { combineReducers } from 'redux';
import UserReducer from './UserReducer';
import ChatReducer from './ChatReducer';
import AppReducer from './AppReducer';

export default combineReducers({
  user: UserReducer,
  chat: ChatReducer,
  app: AppReducer,
});
