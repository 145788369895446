import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import InputMask from 'react-input-mask';
import Icon from '../common/Icon';
import { Colors } from '../../styles';
import Select from 'react-select';
import {
  customStylesSmallScreen,
  customStylesBigScreen
} from '../../styles/modalsStyles';

import * as Yup from 'yup';
import './modal.scss';

const initialState = {
  message: ''
};

const staffMemberSchema = Yup.object({
  message: Yup.string().required('The message text is required.')
});

const ModalNotification = ({ isOpenFlag, closeModal, onSubmitCB }) => {
  const [isLoading, setLoading] = useState(false);
  const formik = useFormik({
    validationSchema: staffMemberSchema,
    initialValues: initialState,
    onSubmit: (values) => {
      setLoading(true);
      onSubmitCB(values).then(() => {
        setLoading(false);
        closeModal();
        formik.resetForm();
      });
    }
  });

  useEffect(() => {
    formik.resetForm();
  }, [isOpenFlag]);

  return (
    <Modal
      isOpen={isOpenFlag}
      onRequestClose={closeModal}
      style={
        window.innerWidth <= 1024
          ? customStylesSmallScreen
          : customStylesBigScreen
      }
      ariaHideApp={false}
    >
      <div className="bg-white modal-switch-component">
        <div className="d-flex p-3">
          <div className="card-body p-0 d-flex align-items-center close-button-container">
            <i
              className={`d-flex justify-content-center align-items-center ms-1 me-3 pointer`}
              onClick={() => closeModal()}
            >
              <Icon icon="close" size={25} color={Colors.blackText} />
            </i>
            <h4
              className="fw-500 font-xs m-0 pointer d-none d-md-block"
              onClick={() => closeModal()}
            >
              Close
            </h4>
          </div>

          <div className="card-body p-0 d-flex justify-content-center">
            <h4 className="fw-600 font-md m-0 mt-1 pointer">
              New Alumni Notification
            </h4>
          </div>
        </div>
        <div className="row d-flex align-content-center pt-4">
          <div
            className={`card shadow-none border-0 ms-auto me-auto login-card pt-4 mt-4`}
          >
            <div className={`card-body rounded-0 px-0 pt-1`}>
              <form
                className="pt-2 sober-track-form"
                onSubmit={formik.handleSubmit}
              >
                <div className="form-group icon-input mb-3">
                  <label className="fw-600 font-xsss text-grey-900 ms-2 ps-2 pb-1">
                    Message
                  </label>

                  <div className="form-group icon-input mb-3">
                    <textarea
                      id="message"
                      name="message"
                      type="text"
                      className="style2-textarea ps-4 py-2 pt-3 form-control text-black font-xsss fw-600 shadow-md"
                      placeholder="Enter Message"
                      onChange={formik.handleChange}
                      value={formik.values.message}
                    />
                    {formik.errors.message && formik.touched.message && (
                      <p className="p-0 mb-0 me-2 text-danger text-center">
                        {formik.errors.message}.
                      </p>
                    )}
                  </div>
                </div>

                <div className="form-group mb-1 mt-5">
                  <button
                    className="form-control text-center text-white fw-600 border-0 p-0 sober-track-btn d-flex align-items-center justify-content-center"
                    type="submit"
                  >
                    <p className="p-0 mb-0 me-2 font-xs fw-500">Send</p>
                    {isLoading ? (
                      <div
                        className="spinner-border loading-indicator me-1"
                        role="status"
                      ></div>
                    ) : (
                      <i
                        className={`d-flex justify-content-center align-items-center ms-2`}
                      >
                        <Icon icon="plus" size={25} color={Colors.white} />
                      </i>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNotification;
