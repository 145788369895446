import axios from 'axios';
import axiosRetry from 'axios-retry';
import { LOOSID_APP_HOME, DiscoursePostsHome } from '../config/constsNew';
const BASE_URL = 'https://api.loosid.cc/v3';
// const FEEDS_BASE_URL = 'https://ag01.loosid.cc/v3';//Production
// const FEEDS_BASE_URL = 'https://ag.loosid.cc/v3';//Staging
const FEEDS_BASE_URL = 'https://loosidfeeds01.loosidapp.net/v3'; // NUEVO PROD (YAY!)
const DISCOURSE_HOTLINE_URL = DiscoursePostsHome;
const REFERRAL_TRACKING_URL = 'https://loosidapp.com';
const ADMIN_API_URL = 'https://adminapi.loosid.cc/v3';
const NOTIFICATION_URL = 'https://notifs01.loosid.cc/v3';
const READ_NOTIFICATIONS_URL = 'https://notifs01.loosid.cc/v3';
const USER_STATS_URL = 'https://lus01.loosidapp.net/v3';
// const USER_STATS_URL = 'https://admin-users.loosid.cc/v3';
const DATING_URL = 'https://lus01.loosidapp.net/v3';
const AUDIO_SERVICE_URL = 'https://audio01.loosid.cc/v3';
const MAINTENANCE_URL = 'https://adminapi.loosid.cc/v3';
const GEO_URL = 'https://geo02.loosidapp.net';
const USER_SEARCH_URL = 'https://dash-api.loosid.cc/v3';
const GET = 'get';
const POST = 'post';
const request_timeout = 10000;
const connection_abort_timeout = 13000;
export default class ApiHandler {
  callAxios = async (
    baseUrl = BASE_URL,
    endpoint,
    method,
    headers = {},
    data = null
  ) => {
    axiosRetry(axios, { retries: 2, shouldResetTimeout: true });
    let response = null;
    let source = axios.CancelToken.source();
    this.abortTimeOut = setTimeout(() => {
      // if the response is null after timeout abort the request
      if (response === null) {
        source.cancel && source.cancel();
      }
    }, connection_abort_timeout);

    response = await axios({
      method: method,
      url: baseUrl + endpoint,
      timeout: request_timeout,
      data: data,
      cancelToken: source.token,
      validateStatus: function (status) {
        return status < 500; // Reject only if the status code is greater than or equal to 500
      },
      headers: headers
    })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          return {
            name: 'Error',
            message: 'Request_cancelled'
          };
        }
        console.log(error.toJSON());
        return error.toJSON();
      })
      .finally(() => {
        if (this.abortTimeOut) clearTimeout(this.abortTimeOut);
      });
    if (response != null) {
      return response;
    } else {
      return {};
    }
  };
  getFeeds = async (headers, queryParams) => {
    const response = await this.callAxios(
      FEEDS_BASE_URL,
      '/feeds' + queryParams,
      GET,
      headers
    );
    return response;
  };
  getTopics = async (headers, queryParams) => {
    const response = await this.callAxios(
      USER_STATS_URL,
      '/topics/' + queryParams,
      GET,
      headers
    );
    return response;
  };
  saveTopicSelections = async (headers, path, data) => {
    const response = await this.callAxios(
      USER_STATS_URL,
      '/topics/' + path,
      POST,
      headers,
      data
    );
    return response;
  };
  getGuides = async () => {
    const res = await this.callAxios(BASE_URL, '/guides', GET);
    return res;
  };

  getListings = async (params) => {
    return await this.callAxios(BASE_URL, '/listings/search' + params, GET);
  };

  getTravelListings = async () => {
    return await this.callAxios(
      BASE_URL,
      '/listings/search?guideName=travel&sortBy=startsAt',
      GET
    );
  };

  getPromotedContentCarousel = async (params) => {
    return await this.callAxios(BASE_URL, '/listings/featured' + params, GET);
  };

  getDiscourseHotlines = async (headers) => {
    return await this.callAxios(
      DISCOURSE_HOTLINE_URL,
      '/c/hotline.json?order="activity"',
      GET,
      headers
    );
  };
  getReferralLink = async (headers, queryParams = '') => {
    return await this.callAxios(
      LOOSID_APP_HOME,
      '/r/info/' + queryParams,
      GET,
      headers
    );
  };

  getReferralCounts = async (headers, userID) => {
    return await this.callAxios(
      REFERRAL_TRACKING_URL,
      '/r/info/{' + userID + '}',
      GET,
      headers
    );
  };

  recordReferralToken = async (headers, data) => {
    return await this.callAxios(
      REFERRAL_TRACKING_URL,
      '/r/user/register',
      POST,
      headers,
      data
    );
  };

  firstTimeInstallTrack = async (headers, data) => {
    return await this.callAxios(
      REFERRAL_TRACKING_URL,
      '/r/user/install',
      POST,
      headers,
      data
    );
  };
  markBlogAsRead = async (headers, data) => {
    return await this.callAxios(
      FEEDS_BASE_URL,
      '/feeds/read',
      POST,
      headers,
      data
    );
  };
  markAdvertisementAsRead = async (headers, data) => {
    return await this.callAxios(
      ADMIN_API_URL,
      '/ads/click',
      POST,
      headers,
      data
    );
  };
  getFeaturedAdvertisements = async (headers) => {
    return await this.callAxios(FEEDS_BASE_URL, '/featured/ads', GET, headers);
  };
  getHotlinesBannerInfo = async (headers, type) => {
    return await this.callAxios(
      FEEDS_BASE_URL,
      `/get_banner?type=${type}`,
      GET,
      headers
    );
  };
  getUserStatsInfo = async (userID, headers) => {
    return await this.callAxios(
      USER_STATS_URL,
      `/wall_header/${userID}`,
      GET,
      headers
    );
  };

  getAudioStories = async (headers) => {
    return await this.callAxios(AUDIO_SERVICE_URL, `/audiofeed`, GET, headers);
  };
  getAudioStoriesForHome = async (headers) => {
    return await this.callAxios(AUDIO_SERVICE_URL, `/audiohome`, GET, headers);
  };
  getAudio = async (audioID, headers) => {
    return await this.callAxios(
      AUDIO_SERVICE_URL,
      `/audioitem/${audioID}`,
      GET,
      headers
    );
  };
  getNotifications = async (headers) => {
    return await this.callAxios(
      NOTIFICATION_URL,
      '/notifications',
      GET,
      headers
    );
  };
  readNotifications = async (headers, data) => {
    return await this.callAxios(
      READ_NOTIFICATIONS_URL,
      '/notification/read',
      POST,
      headers,
      data
    );
  };
  getMaintenance = async (headers, data) => {
    return await this.callAxios(MAINTENANCE_URL, '/status', GET, headers);
  };
  getGeoLocalitation = async (headers, data) => {
    return await this.callAxios(
      GEO_URL,
      '/users/register',
      POST,
      headers,
      data
    );
  };
  getStreamChatToken = async (headers, data) => {
    return await this.callAxios(
      USER_STATS_URL,
      '/getstream/token',
      GET,
      headers
    );
  };
  getLoosidUsersList = async (headers, queryParams) => {
    return await this.callAxios(
      USER_SEARCH_URL,
      '/users/search' + queryParams,
      GET,
      headers
    );
  };
  getDatingSuggestions = async (headers, queryParams) => {
    return await this.callAxios(
      DATING_URL,
      '/dating/suggestions' + queryParams,
      GET,
      headers
    );
  };
  getDatingLikes = async (headers, queryParams) => {
    return await this.callAxios(
      DATING_URL,
      '/dating/likes' + queryParams,
      GET,
      headers
    );
  };
  getDatingMatches = async (headers, queryParams) => {
    return await this.callAxios(
      DATING_URL,
      '/dating/matches' + queryParams,
      GET,
      headers
    );
  };
  likeDatingUser = async (headers, data) => {
    return await this.callAxios(
      DATING_URL,
      '/dating/like',
      POST,
      headers,
      data
    );
  };
  ignoreDatingUser = async (headers, data) => {
    return await this.callAxios(
      DATING_URL,
      '/dating/ignore',
      POST,
      headers,
      data
    );
  };
  createDatingUser = async (headers, data) => {
    return await this.callAxios(
      DATING_URL,
      '/dating/user',
      POST,
      headers,
      data
    );
  };
  updateDatingUser = async (userId, headers, data) => {
    return await this.callAxios(
      DATING_URL,
      `/dating/user/${userId}`,
      POST,
      headers,
      data
    );
  };
  getDatingUser = async (userId, headers) => {
    return await this.callAxios(
      DATING_URL,
      `/dating/user/${userId}`,
      GET,
      headers
    );
  };
}
