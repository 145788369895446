const wrappers = {
  getItem(key) {
    return localStorage.getItem(key);
  },

  setItem(key, value) {
    localStorage.setItem(key, value);
  },

  rmItem(key) {
    localStorage.removeItem(key)
  }
}
export class MyStorage {


  user_token = '@user_token';


  clearAppData() {
    this.removeUserToken()
  }


  setUserToken(info) {
    wrappers.setItem(this.user_token, info);
  }

  getUserToken() {
    return wrappers.getItem(this.user_token);
  }

  removeUserToken() {
    return wrappers.rmItem(this.user_token);
  }


}
